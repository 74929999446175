import config from "../../config/config.json";
import configStaging from "../../config/config.staging.json";
import configDevelop from "../../config/config.develop.json";
import i18n from "../../i18n";

let instances = {};

class Config {
  constructor(host = "meet.jobs") {
    this.host = typeof window === "undefined" ? host : window.location.host;
    this.config = config;
    // if (this.host.search("localhost") !== -1) this.config = configDevelop;
    // if(this.host.search('staging.meet.jobs') !== -1) this.config = configStaging;
    if (!instances.hasOwnProperty(this.host)) instances[this.host] = this;
    return instances[this.host];
  }

  api_url(path) {
    return this.config.api_uri + path;
  }

  api_url_v2(path) {
    return this.config.api_uri_v2 + path;
  }

  url_origin() {
    return (
      `${this.host === "localhost:3000" ? "http://" : "https://"}` + this.host
    );
  }

  favicon_pathname() {
    return "/public_assets/images/" + this.config.favicon_file_name;
  }

  facebook_app_id() {
    return this.config.facebook_app_id;
  }

  facebook_event_token() {
    return this.config.facebook_event_token;
  }

  gaCode() {
    return this.config.ga;
  }

  gtmCode() {
    return this.config.gtm;
  }

  sentry_dns() {
    return this.config.sentry_dns;
  }

  getTawkToSiteId() {
    if (this.host.search("localhost") !== -1) {
      return this.config.tawkToDevelopment.siteId;
    }
    return this.config.tawkTo.siteId;
  }

  getTawkToChannelHash() {
    if (this.host.search("localhost") !== -1) {
      return this.config.tawkToDevelopment.channelHash[i18n.language];
    }
    return this.config.tawkTo.channelHash[i18n.language];
  }
}

export default Config;
