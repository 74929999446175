export default {
  this_job_is_no_longer_accepting_applicants_: "這個工作機會已停止接受應徵",
  this_job_is_in_the_process_of_editing_and_not_accepting_applicants_:
    "這個工作機會仍在編輯階段，尚未開放應徵",
  browse_more_jobs: "瀏覽其他工作機會",
  salary: "薪資",
  job_type: "工作類型",
  negotiable_description: "面議（常態性薪資月薪 4 萬元台幣以上）",
  remote: "遠端",
  allowed: "允許",
  last_updated: "上次更新",
  required_skills: "技能需求",
  job_description: "工作機會描述",
  employer_info: "企業資訊",
  other_jobs_from_this_employer: "此企業的其他工作機會",
  no_other_jobs_: "沒有其他工作機會。",
  get___reward_from_meet_jobs_when_you_are_successfully_hired_:
    "順利就職，Meet.jobs 就提供 <span class='reward'>100 USD</span> 的獎金。",
  we_will_redirect_you: "請至原網站應徵",
  redirect_description:
    "按下「應徵」後，系統將帶您到此工作機會的原網站。若您順利錄取此工作機會，Meet.jobs 將額外提供您 <span class='reward'>100 USD</span> 的就職祝賀金，記得回 Meet.jobs 回報錄取！",
  you_have_a_pending_invitation: "您有一個等待中的邀請",
  if_you_refer_without___you_will_not_get_any_referral_reward_:
    "如果您沒有 {{log_in}} 後再推薦，就會損失了獲得高額推薦獎金的機會！",
  hi__i_found_a_great_job_you_might_be_interested_come_and_check_it_out_:
    "嗨！我覺得這個工作機會很不錯，推薦你看看～",
  refer_to_a_friend_and_get___reward_with_successful_hire_:
    "將這份工作推薦給朋友，順利就職後可獲得 {{reward}} 的推薦感謝金。",
  referred_by: "推薦人",
  you_have_applied_on___: "您已在{{applied_at}}應徵過此工作機會。",
  you_have_applied_before: "已應徵過此工作機會",
  applied_reminder_description:
    "您已在{{applied_at}}應徵過此工作機會。您可以在「我的應徵」中查看應徵的細節，或再次應徵這個工作機會。",
  apply_again: "再次應徵",
  go_to_job_page: "前往工作機會",
  ok: "我知道了",
};
