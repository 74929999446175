import React from "react";
import { Link } from "react-router-dom";
import { SaveJobButtonWrapper } from "../../containers/general.jsx";
import { withNamespaces } from "react-i18next";
import { ReactComponent as IconLocation } from "../../../assets/images/icon/icon_24_location_BG400.svg";
import InternalUri from "../../models/internal_uri";
import { ReactComponent as IconSpeedInterview } from "../../../assets/images/icon/speed_interview.svg";
import { ReactComponent as IconRefer } from "../../../assets/images/icon/icon_24_refer_BG400.svg";
import { ReactComponent as IconQuestion } from "../../../assets/images/icon/icon_24_question_B100.svg";

const JobCard = (props) => {
  const { job, referral_code, t } = props;
  const internal_uri = new InternalUri();

  const eventCategories = props.eventCategories
    ? props.eventCategories
    : {
        whole: "",
        employer: "",
        location: "",
        save: "",
      };

  const goLocation = (e, query_string) => {
    e.preventDefault();
    props.updateSearchKeywords("", query_string);
    if (
      props.location.pathname.search("/jobs") === -1 || // not jobs page
      (props.location.pathname.search("/jobs") !== -1 && // is job page
        props.location.pathname.search("/jobs/") !== -1)
    ) {
      props.history.push(internal_uri.formatted_frontend_jobs_page_path());
    }
  };

  return (
    <div
      className={`job-card card ${job.has_event() ? "with-event-badge" : ""}`}
    >
      <Link
        to={
          `${internal_uri.formatted_frontend_job_page_path(job.id, job.slug)}` +
          `${referral_code ? `?referral_code=${referral_code}` : ""}`
        }
        className="job-card-link"
        data-event-category={eventCategories.whole}
      />
      {job.has_event() ? (
        <div className="event-badge">
          <IconSpeedInterview />
          <h5>{t(job.events[0].get_i18n_key())}</h5>
        </div>
      ) : (
        ""
      )}
      <div className="card-body clearfix">
        <div className="logo">
          <div
            className="image-wrapper"
            style={{
              backgroundImage: `url(${
                job.has_employer()
                  ? job.employer.formatted_logo_dashboard_url()
                  : job.formatted_external_employer_logo_url()
              })`,
            }}
          />
        </div>
        <SaveJobButtonWrapper
          color="grey"
          size="24"
          job={job}
          eventCategory={eventCategories.save}
        />
        <h3 className="job-title">{job.title}</h3>
        {job.has_employer() ? (
          <Link
            className="employer-name-wrapper"
            to={`${internal_uri.formatted_frontend_employer_page_path(
              job.employer.id,
              job.employer.slug
            )}${referral_code ? `?referral_code=${referral_code}` : ""}`}
            target="_blank"
            rel="noopener noreferrer"
            data-event-category={eventCategories.employer}
          >
            <h5 className="employer-name">{job.formatted_employer_name()}</h5>
          </Link>
        ) : (
          <h5 className="employer-name">{job.formatted_employer_name()}</h5>
        )}

        <ul className="company-items">
          <li>
            <button
              onClick={(e) => goLocation(e, job.formatted_primary_location())}
              data-event-category={eventCategories.location}
            >
              <h6 className="location">
                <IconLocation />
                {job.formatted_primary_location()}
              </h6>
            </button>
          </li>
        </ul>
      </div>

      <div className="card-bottom">
        {/* TODO: 超過 3 位數會跑版 */}
        <span className="past-days smallest-text">
          {job.formatted_shortcut_for_past_time()}
        </span>
        <div className="item">
          {job.is_negotiable() ? (
            <p className="negotiable">
              <span className="tooltip-wrapper right">
                {t("negotiable")}
                <IconQuestion />
                <span className="tooltip">
                  <h6>{t("negotiable_description")}</h6>
                </span>
              </span>
            </p>
          ) : (
            <p className="salary">
              <span className="range-and-currency">{` ${job.formatted_salary_range()} ${job.formatted_salary_currency()}`}</span>
              <span className="paid-period">
                {job.formatted_salary_paid_period()}
              </span>
            </p>
          )}
        </div>
        {job.referralable ? (
          <div className="item">
            <h6 className="referral-reward-box">
              <IconRefer />
              {t("referral_reward")} <span>200 USD</span>
            </h6>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default withNamespaces(["general", "events"])(JobCard);
