import React from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import InternalUri from "../../models/internal_uri";
import icon_remote from "../../../assets/images/icon/icon_24_remote_B100.svg";
import Job from "../../models/job";
import Employer from "../../models/employer";
import icon_redirect from "../../../assets/images/icon/icon_24_redirect_B100.svg";
import { ReactComponent as IconSpeedInterview } from "../../../assets/images/icon/speed_interview.svg";

class JobInfo extends React.Component {
  constructor(props) {
    super(props);
    this.goLocation = this.goLocation.bind(this);
    this.goTag = this.goTag.bind(this);
    this.state = {};
  }

  goLocation = (e, query_string) => {
    e.preventDefault();
    e.stopPropagation();
    const internal_uri = new InternalUri();
    this.props.updateSearchKeywords("", query_string);
    this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
  };

  goTag = (e, query_string) => {
    e.preventDefault();
    e.stopPropagation();
    const internal_uri = new InternalUri();
    this.props.updateSearchKeywords(query_string, "");
    this.props.history.push(internal_uri.formatted_frontend_jobs_page_path());
  };

  render() {
    const job = new Job(this.props.job);
    const employer = new Employer(this.props.employer);
    const {
      referral_user,
      loading,
      show_link_for_job_page,
      show_event,
    } = this.props;
    const { goTag, goLocation } = this;
    const internal_uri = new InternalUri();
    const { t } = this.props;

    const eventCategories = this.props.eventCategories
      ? this.props.eventCategories
      : {
          headEmployer: "",
          headLocation: "",
          requiredSkill: "",
          employerInfo: "",
        };

    if (loading) {
      return (
        <div className="job-info">
          <div className="job-intro block">
            <h2 className="text text-240">{`${t("loading")}...`}</h2>
            <p className="basic-info">
              <span className="text text-160">{`${t("loading")}...`}</span>
            </p>
            <hr />
            <div>
              <h5 className="label">{t("salary")}</h5>
              <h3 className="salary text text-160">{`${t("loading")}...`}</h3>
            </div>
            <div className="item-blocks item-blocks-2">
              <div className="item-block">
                <h5 className="label">{t("job_type")}</h5>
                <p>
                  <span className="text text-160">{`${t("loading")}...`}</span>
                </p>
              </div>
              <div className="item-block">
                <h5 className="label">{t("last_updated")}</h5>
                <p>
                  <span className="text text-80">{`${t("loading")}...`}</span>
                </p>
              </div>
            </div>
            <div>
              <h5 className="label">{t("general:job_function")}</h5>
              <p>
                <span className="text text-160">{`${t("loading")}...`}</span>
              </p>
            </div>
            <div>
              <h5 className="label">{t("required_skills")}</h5>
              <ul className="box-items">
                <li>
                  <div className="tag-box smallest-text">{`${t(
                    "loading"
                  )}...`}</div>
                </li>
                <li>
                  <div className="tag-box smallest-text">{`${t(
                    "loading"
                  )}...`}</div>
                </li>
                <li>
                  <div className="tag-box smallest-text">{`${t(
                    "loading"
                  )}...`}</div>
                </li>
              </ul>
            </div>
          </div>

          <div className="job-description block clearfix">
            <h4>{t("job_description")}</h4>
            <div className="from-editor">
              <p className="text text-240">{`${t("loading")}...`}</p>
              <p className="text text-160">{`${t("loading")}...`}</p>
              <p className="text text-480">{`${t("loading")}...`}</p>
              <p className="text text-240">{`${t("loading")}...`}</p>
              <p className="text text-160">{`${t("loading")}...`}</p>
            </div>
          </div>

          <div>
            <h4 className="title-outside">{t("employer_info")}</h4>
            <div className="employer-card block">
              <div className="logo">
                <div className="image-wrapper" />
              </div>
              <h4 className="text text-160">{`${t("loading")}...`}</h4>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="job-info">
        <div
          className={`job-intro block ${
            job.has_event() && show_event ? "with-event-badge" : ""
          }`}
        >
          {job.has_event() && show_event ? (
            <div className="event-badge">
              <IconSpeedInterview />
              <h5>{t(job.events[0].get_i18n_key())}</h5>
            </div>
          ) : (
            ""
          )}
          {show_link_for_job_page ? (
            <div className="title-with-icon-wrapper">
              <h2>{job.formatted_title()}</h2>
              <Link
                to={internal_uri.formatted_frontend_job_page_path(
                  job.id,
                  job.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={icon_redirect} alt="" />
              </Link>
            </div>
          ) : (
            <h2>{job.formatted_title()}</h2>
          )}
          <p className="basic-info">
            {job.has_employer() ? (
              <Link
                to={
                  `${internal_uri.formatted_frontend_employer_page_path(
                    employer.id,
                    employer.slug
                  )}` +
                  `${
                    Object.keys(referral_user).length
                      ? `?referral_code=${referral_user.referral_code}`
                      : ""
                  }`
                }
                data-event-category={eventCategories.headEmployer}
              >
                <span className="employer-name">
                  {job.formatted_employer_name()}
                </span>
              </Link>
            ) : (
              <span className="employer-name">
                {job.formatted_employer_name()}
              </span>
            )}
            {` ‧ `}
            <a
              href="/#"
              onClick={(e) => goLocation(e, job.formatted_primary_location(t))}
              data-event-category={eventCategories.headLocation}
            >
              <span className="primary-location">
                {job.formatted_primary_location(t)}
              </span>
            </a>
          </p>
          <hr />
          {job.has_salary() ? (
            <div>
              <h5>{t("salary")}</h5>
              {job.is_negotiable() ? (
                <h3 className="salary">{t("negotiable_description")}</h3>
              ) : (
                <h3 className="salary">
                  {job.formatted_salary_range()}{" "}
                  {job.formatted_salary_currency()}
                  <span>{job.formatted_salary_paid_period()}</span>
                </h3>
              )}
            </div>
          ) : (
            ""
          )}
          <div
            className={`item-blocks item-blocks-${
              job.is_remoteable() ? "3" : "2"
            }`}
          >
            <div className="item-block">
              <h5>{t("job_type")}</h5>
              <p>
                {job.formatted_work_type(t)} ‧ {job.formatted_contract_type(t)}
              </p>
            </div>
            {job.is_remoteable() ? (
              <div className="item-block remote">
                <h5>{t("remote")}</h5>
                <p>
                  <img src={icon_remote} alt="" />
                  {t("allowed")}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="item-block">
              <h5>{t("last_updated")}</h5>
              <p>{job.formatted_past_time(t)}</p>
            </div>
          </div>
          {job.job_functions && job.job_functions.length ? (
            <div>
              <h5>{t("general:job_function")}</h5>
              <p>
                {job.job_functions
                  .filter((job_function) => job_function.is_published)
                  .map((job_function) => t(job_function.get_i18n_key()))
                  .join(" ‧ ")}
              </p>
            </div>
          ) : (
            ""
          )}
          {!job.required_skills || job.required_skills.length === 0 ? (
            ""
          ) : (
            <div>
              <h5>{t("required_skills")}</h5>
              <ul className="box-items">
                {job.required_skills.map((skill, i) => (
                  <li key={i}>
                    <div
                      onClick={(e) => goTag(e, skill.name)}
                      className="tag-box smallest-text"
                      data-event-category={eventCategories.requiredSkill}
                    >
                      {skill.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="job-description block clearfix">
          <h4>{t("job_description")}</h4>
          {job.has_description() ? (
            <div
              className="from-editor"
              dangerouslySetInnerHTML={{ __html: job.description }}
            />
          ) : (
            t("general:_not_provided_")
          )}
        </div>

        <h4 className="title-outside">{t("employer_info")}</h4>
        {job.has_employer() ? (
          <Link
            className="employer-card card"
            to={
              `${internal_uri.formatted_frontend_employer_page_path(
                employer.id,
                employer.slug
              )}` +
              `${
                Object.keys(referral_user).length
                  ? `?referral_code=${referral_user.referral_code}`
                  : ""
              }`
            }
            data-event-category={eventCategories.employerInfo}
          >
            <div className="logo">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${employer.formatted_logo_dashboard_url()})`,
                }}
              />
            </div>
            <h4>{job.formatted_employer_name()}</h4>
            {employer.has_slogan() ? (
              <p className="slogan from-editor">{employer.slogan}</p>
            ) : (
              ""
            )}
          </Link>
        ) : (
          <div className="employer-card block">
            <div className="logo">
              <div
                className="image-wrapper"
                style={{
                  backgroundImage: `url(${job.formatted_external_employer_logo_url()})`,
                }}
              />
            </div>
            <h4>{job.formatted_employer_name()}</h4>
            {job.external_employer_url ? (
              <a
                className="link"
                href={job.external_employer_url}
                target="_blank"
                rel="noopener noreferrer"
                data-event-category="415_employer_external_link"
              >
                <h5>{job.external_employer_url}</h5>
              </a>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

JobInfo.defaultProps = {
  job: {},
  employer: {},
  referral_user: {},
  loading: true,
  show_link_for_job_page: false,
};

export default withNamespaces(["frontend_job_page", "general"])(JobInfo);
