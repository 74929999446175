import React from "react";
import Select from "react-select";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers.js";
import axios from "axios";
import { compare, get_options } from "../../../../assets/js/functions.js";
import Job from "../../../models/job.js";
import Employer from "../../../models/employer.js";
import moment from "moment/moment";
import { withNamespaces, Interpolate } from "react-i18next";
import { CSSTransitionGroup } from "react-transition-group";
import Editor from "../Editor.jsx";
import { Link } from "react-router-dom";
import icon_arrow from "../../../../assets/images/icon/icon_24_arrow_left_BG400.svg";
import plan_post from "../../../../assets/images/i010-plan_01.svg";
import plan_hire from "../../../../assets/images/i011-plan_02.svg";
import plan_hire_plus from "../../../../assets/images/i012-plan_03.svg";
import {
  addCommasToNumber,
  page_smothly_scroll_to,
  raw_html_to_editor_content,
  removeCommasToNumber,
} from "../../../../assets/js/functions";
import { isNumeral } from "../../../../assets/js/validators.js";
import InternalUri from "../../../models/internal_uri";
import InputField from "../../general/InputField";
import { numeral, required } from "../../../../assets/js/validations";
import Salary from "../../../../assets/js/formats/salary";
import JobFunction from "../../../models/job_function";
import JobFunctionModal from "../modals/JobFunctionModal";

const jobFunctionGroup = [
  [13, 5, 4, 3, 6, 7, 39, 17, 10, 40],
  [18, 31, 53, 52],
  [11, 12, 43, 42, 41],
  [23, 8, 49, 20, 50, 24, 51],
  [9, 44, 45, 16, 47, 46, 48],
  [22],
  [21],
  [25],
  [54],
  [26],
];

const config = new Config();
const headers = new ApiHeaders();
const salary = new Salary();

class JobPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.updateActiveCompany = this.updateActiveCompany.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
    this.handleJobFunctionsInputChange = this.handleJobFunctionsInputChange.bind(
      this
    );
    this.remove_job_functions_selected_values = this.remove_job_functions_selected_values.bind(
      this
    );
    this.handleSkillsInputChange = this.handleSkillsInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);

    // check pending data
    this.checkPendingData = this.checkPendingData.bind(this);

    // actions bar
    this.discard = this.discard.bind(this);
    this.saveJob = this.saveJob.bind(this);
    this.postNewJob = this.postNewJob.bind(this);
    this.postJob = this.postJob.bind(this);
    this.saveNewDraft = this.saveNewDraft.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.createRequirementSkill = this.createRequirementSkill.bind(this);
    this.handleJobFunctionShow = this.handleJobFunctionShow.bind(this);

    this.state = {
      active_company_id: -1,
      creator_user_id: -1,
      active_mode: "",
      active_job: {
        id: -1,
        title: "",
        published_at: "",
        published_state_key: null,
      },
      form_data: {},
      original_form_data: {},
      has_pending_data: false,
      options: {},
      error_messages: {},
      loading: true,
      publishing: false,
      showJobFunctions: false,
    };
    this.validateMethods = {};
  }

  handleRadioChange(e, property_name, value) {
    this.setState((prev_state) => ({
      ...prev_state,
      form_data: {
        ...prev_state.form_data,
        [property_name]: value,
      },
    }));
  }

  handleJobFunctionShow(status) {
    this.setState({ showJobFunctions: status });
  }

  remove_job_functions_selected_values = (option) => {
    const { t } = this.props;
    let error_messages = { ...this.state.error_messages };
    const job_functions_selected_values = this.state.form_data
      .job_functions_selected_values;
    let job_functions_errors = [];
    const targetIdx = job_functions_selected_values.findIndex(({ value }) => {
      return value === option.value;
    });
    if (targetIdx >= 0) {
      job_functions_selected_values.splice(targetIdx, 1);
    }
    const countOfSelectedJobFunction = job_functions_selected_values.length;
    if (countOfSelectedJobFunction <= 0)
      job_functions_errors.push(t("error_messages:required"));
    if (countOfSelectedJobFunction > 3)
      job_functions_errors.push(
        t("error_messages:selectMaximumLimit", { number: 3 })
      );
    error_messages = {
      ...error_messages,
      job_functions: job_functions_errors,
    };

    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          job_functions_selected_values,
        },
        error_messages,
      }),
      () => this.checkPendingData()
    );
  };

  componentDidMount() {
    this._isMounted = true;

    let options = {
      work_types: get_options("", {
        full_time: "states:full_time",
        part_time: "states:part_time",
        internship: "states:internship",
        volunteer: "states:volunteer",
      }),
      paid_periods: get_options("", {
        annually: "states:annually",
        monthly: "states:monthly",
        daily: "states:daily",
        hourly: "states:hourly",
      }),
      contract_types: get_options("", {
        permanent: "states:permanent",
        contract: "states:contract",
      }),
    };

    let axiosOptions = {
      method: "GET",
      url: config.api_url(`/job_meta/currency_types`),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(axiosOptions)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          options = {
            ...options,
            currency_types: get_options("currency_types", response.data),
          };

          return axios({
            method: "GET",
            url: config.api_url(`/job_meta/tags/job_functions`),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          });
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const raw_job_functions = response.data;
          let job_functions_options = [];
          for (let i = 0; i < raw_job_functions.length; i++) {
            const job_function = new JobFunction(raw_job_functions[i]);
            job_functions_options.push({
              value: job_function.id,
              label: job_function.get_i18n_key(),
            });
          }
          options = {
            ...options,
            job_functions: job_functions_options,
          };

          return axios({
            method: "GET",
            url: config.api_url(`/job_meta/requirement_skills`),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          });
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          this.setState(
            (prev_state) => ({
              ...prev_state,
              options: {
                ...options,
                requirement_skills: get_options(
                  "requirement_skills",
                  response.data
                ),
              },
              loading: true,
              error_messages: {},
            }),
            () =>
              this.updateActiveCompany(
                this.props.current_user.active_company.id
              )
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (
      this.props.current_user.active_company.id !==
      next_props.current_user.active_company.id
    ) {
      const internal_uri = new InternalUri();
      this.props.history.push(
        internal_uri.formatted_dashboard_jobs_page_path()
      );
    }

    if (
      next_props.match.params.id !== this.props.match.params.id ||
      next_props.match.params.mode !== this.props.match.params.mode
    ) {
      this.setState(
        {
          loading: true,
        },
        () => this.updateActiveCompany(this.state.active_company_id)
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateActiveCompany(company_id) {
    const raw_mode = this.props.match.params.mode;
    let mode;
    switch (raw_mode) {
      case "update":
      case "reopen":
        mode = raw_mode;
        break;
      case "edit":
      default:
        mode = "edit";
        break;
    }

    const { current_user } = this.props;
    if (
      current_user.employer_roles[company_id].access_control_list
        .jobs_manageable
    ) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          active_company_id: company_id,
          active_mode: mode,
          access_control_list:
            current_user.employer_roles[company_id].access_control_list,
          loading: true,
        }),
        () => this.fetchData()
      );
    } else {
      this.setState((prev_state) => ({
        ...prev_state,
        active_company_id: company_id,
        active_mode: mode,
        access_control_list:
          current_user.employer_roles[company_id].access_control_list,
        loading: false,
      }));
    }
  }

  fetchData() {
    let options = {
      method: "GET",
      url: config.api_url(
        `/employers/${this.state.active_company_id}?include=creator,addresses`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          const raw_company = response.data;
          const company = new Employer(raw_company);

          this.setState((prev_state) => ({
            ...prev_state,
            creator_user_id: company.creator.id,
            options: {
              ...prev_state.options,
              locations: get_options("locations", company.addresses),
            },
            companyAddresses: company.addresses,
          }));

          options = {
            method: "GET",
            url: config.api_url(
              `/employers/${this.state.active_company_id}/roles?include=roleships`
            ),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          };
          return axios(options);
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const option_roleships = get_options("roleships", response.data);
          this.setState((prev_state) => ({
            ...prev_state,
            options: {
              ...prev_state.options,
              roleships: option_roleships,
            },
          }));

          options = {
            method: "GET",
            url: config.api_url(
              `/employers/${this.state.active_company_id}/jobs/${this.props.match.params.id}?include=job_roleships`
            ),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          };
          return axios(options);
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const raw_job = response.data;
          const job = new Job(raw_job);
          const { options } = this.state;

          // TODO: 重要 1. 請 tyler 檢查這邊的權限限制？ 2. include 不到東西時，也算是 403。
          if (!job.job_roleships) {
            const internal_uri = new InternalUri();
            this.props.history.replace(
              internal_uri.formatted_dashboard_jobs_page_path()
            );
          }

          let job_functions_selected_values = [];
          if (job.job_functions.length) {
            for (let i = 0; i < job.job_functions.length; i++) {
              let job_function = job.job_functions[i];
              job_functions_selected_values.push({
                value: job_function.id,
                label: job_function.get_i18n_key(),
              });
            }
          }

          let requirement_skills_selected_values = [];
          if (job.required_skills.length) {
            for (let i = 0; i < job.required_skills.length; i++) {
              let skill = job.required_skills[i];
              requirement_skills_selected_values.push({
                value: skill.id,
                label: skill.name,
              });
            }
          }

          let roleships_selected_values = [];
          if (job.job_roleships.length) {
            for (let i = 0; i < job.job_roleships.length; i++) {
              let roleship = job.job_roleships[i];
              // 過濾掉不在現有成員 list 內的狀況
              for (let j = 0; j < options.roleships.length; j++) {
                if (
                  options.roleships[j].roleship_id ===
                  roleship.employer_roleship_id
                ) {
                  roleships_selected_values.push(roleship.employer_roleship_id);
                }
              }
            }
          }

          let locations_selected_value = -1;
          if (options.locations.length) {
            // employer 有 address
            if (job.address) {
              // job 有 address
              for (let i = 0; i < options.locations.length; i++) {
                if (options.locations[i].value === job.address.id) {
                  locations_selected_value = job.address.id;
                }
              }
            }

            if (locations_selected_value === -1)
              locations_selected_value = options.locations[0].value;
          }

          let form_data = {
            title: job.formatted_form_title(),
            work_types_selected_value: job.work_type_key
              ? job.work_type_key
              : options.work_types[0].value,
            contract_types_selected_value: job.contract_type_key
              ? job.contract_type_key
              : options.contract_types[0].value,
            remote_checked: job.contract ? job.contract.remote_working : false,
            minimum_salary: addCommasToNumber(job.salary.minimum),
            maximum_salary: addCommasToNumber(job.salary.maximum),
            currency_types_selected_value: job.salary.currency
              ? job.salary.currency
              : options.currency_types[0].value,
            paid_periods_selected_value: job.salary.paid_period_key
              ? job.salary.paid_period_key
              : options.paid_periods[0].value,
            locations_selected_value,
            job_functions_selected_values,
            requirement_skills_selected_values: requirement_skills_selected_values,
            roleships_selected_values,
            description: raw_html_to_editor_content(
              job.description ? job.description : ""
            ),
            plan_name: job.plan_name || "post_only",
            salary_negotiable: job.salary.negotiable || false,
            // TODO: 還缺下面這三個欄位功能
            // expired_date: {},
            // selected_option_tag: '',
          };

          let original_form_data = {
            ...JSON.parse(JSON.stringify(form_data)),
            locations_selected_value: job.address ? job.address.id : -1,
          };

          let active_job = {
            id: job.id,
            title: job.formatted_title(),
            published_at: job.published_at,
            published_state_key: job.published_state_key,
          };

          this.setState(
            (prev_state) => ({
              ...prev_state,
              active_job,
              form_data,
              original_form_data,
              error_messages: {},
              loading: false,
            }),
            () => this.checkPendingData()
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          // 當 url 有 id 資訊，又是有權限的頁面時的錯誤處理方式
          if (
            error.response.status === 401 ||
            error.response.status === 403 ||
            error.response.status === 404
          ) {
            const internal_uri = new InternalUri();
            this.props.history.replace(
              internal_uri.formatted_dashboard_jobs_page_path()
            );
          }
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            loading: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  handleSelectChange = (property_name, option) => {
    if (option) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          form_data: {
            ...prev_state.form_data,
            [property_name]: option.value,
          },
        }),
        () => this.checkPendingData()
      );
    }
  };

  handleMultiSelectChange = (property_name, options) => {
    if (options) {
      // check job_functions selected count
      const { t } = this.props;
      let error_messages = { ...this.state.error_messages };
      if (property_name === "job_functions_selected_values") {
        const countOfSelectedJobFunction = options.length;
        let job_functions_errors = [];
        if (countOfSelectedJobFunction <= 0)
          job_functions_errors.push(t("error_messages:required"));
        if (countOfSelectedJobFunction > 3)
          job_functions_errors.push(
            t("error_messages:selectMaximumLimit", { number: 3 })
          );
        error_messages = {
          ...error_messages,
          job_functions: job_functions_errors,
        };
      }

      this.setState(
        (prev_state) => ({
          ...prev_state,
          form_data: {
            ...prev_state.form_data,
            [property_name]: options,
          },
          error_messages,
        }),
        () => this.checkPendingData()
      );
    }
  };

  handleJobFunctionsInputChange = (option) => {
    const { t } = this.props;
    let error_messages = { ...this.state.error_messages };
    const job_functions_selected_values = this.state.form_data
      .job_functions_selected_values;
    let job_functions_errors = [];

    const targetIdx = job_functions_selected_values.findIndex(({ value }) => {
      return value === option.value;
    });

    if (targetIdx >= 0) {
      job_functions_selected_values.splice(targetIdx, 1);
    } else {
      job_functions_selected_values.push(option);
    }
    const countOfSelectedJobFunction = job_functions_selected_values.length;
    if (countOfSelectedJobFunction <= 0)
      job_functions_errors.push(t("error_messages:required"));
    if (countOfSelectedJobFunction > 3)
      job_functions_errors.push(
        t("error_messages:selectMaximumLimit", { number: 3 })
      );
    error_messages = {
      ...error_messages,
      job_functions: job_functions_errors,
    };

    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          job_functions_selected_values,
        },
        error_messages,
      }),
      () => this.checkPendingData()
    );
  };

  handleSkillsInputChange = (new_input) => {
    if (new_input.replace(/\s/g, "").length) {
      let new_options_count = 0;
      for (
        let i = 0;
        i < this.state.form_data.requirement_skills_selected_values.length;
        i++
      ) {
        if (
          this.state.form_data.requirement_skills_selected_values[i].value < 0
        ) {
          new_options_count++;
        }
      }
      let options = {
        method: "GET",
        url: config.api_url(`/job_meta/requirement_skills?q=${new_input}`),
        headers: headers.getItemsFromLocalStorage(),
        json: true,
      };
      return axios(options)
        .then((response) => {
          headers.updateItemsToLocalStorage(response.headers);
          this.props.updateConnectionOffModal(false);

          if (this._isMounted) {
            let options_requirement_skills = get_options(
              "requirement_skills",
              response.data
            );

            let skill_exist = false;
            if (options_requirement_skills.length) {
              for (let i = 0; i < options_requirement_skills.length; i++) {
                if (options_requirement_skills[i].label === new_input) {
                  skill_exist = true;
                  break;
                }
              }
            }

            const requirement_skills = skill_exist
              ? options_requirement_skills
              : [
                  { value: -1 * (new_options_count + 1), label: new_input },
                  ...options_requirement_skills,
                ];

            this.setState((prev_state) => ({
              ...prev_state,
              options: {
                ...prev_state.options,
                requirement_skills,
              },
              error_messages: {},
            }));
          }
        })
        .catch((error) => {
          if (error.response) {
            headers.updateItemsToLocalStorage(error.response.headers);
            if (error.response.status === 401) window.location.reload();
          }

          if (this._isMounted) {
            if (typeof error.response === "undefined") {
              window.location.reload();
              this.props.updateConnectionOffModal(true);
            }

            this.setState((prev_state) => ({
              ...prev_state,
              error_messages:
                error.response &&
                error.response.data &&
                error.response.data.status === "error"
                  ? error.response.data.errors
                  : {
                      full_message:
                        "There was an error. Please try again later.",
                    },
              loading: false,
            }));

            page_smothly_scroll_to(0, 300);
          }
        });
    }
  };

  handleInputChange = (propertyName, value, errorMessages) => {
    this.setState(
      (prevState) => {
        let form_data = {
          ...prevState.form_data,
          [propertyName]: value,
        };
        let error_messages = {
          ...prevState.error_messages,
          [propertyName]: errorMessages,
        };

        // check if minimum salary is greater than maximum salary, and add error message to maximum salary field
        if (
          propertyName === "minimum_salary" ||
          propertyName === "maximum_salary"
        ) {
          let maximumSalaryErrorMessages =
            error_messages["maximum_salary"] || [];
          const minimumSalaryWithoutCommas = removeCommasToNumber(
            propertyName === "minimum_salary" ? value : form_data.minimum_salary
          );
          const maximumSalaryWithoutCommas = removeCommasToNumber(
            propertyName === "maximum_salary" ? value : form_data.maximum_salary
          );
          const errorMessage = this.props.t(
            "error_messages:maximumSalaryMustBeGreater"
          );
          const index = maximumSalaryErrorMessages.indexOf(errorMessage);
          if (
            isNumeral(minimumSalaryWithoutCommas) &&
            isNumeral(maximumSalaryWithoutCommas)
          ) {
            if (
              parseFloat(minimumSalaryWithoutCommas) >
              parseFloat(maximumSalaryWithoutCommas)
            ) {
              if (index === -1) maximumSalaryErrorMessages.push(errorMessage);
            } else {
              if (index !== -1) maximumSalaryErrorMessages.splice(index, 1);
            }
          } else {
            if (index !== -1) maximumSalaryErrorMessages.splice(index, 1);
          }
          error_messages["maximum_salary"] = maximumSalaryErrorMessages;
        }

        return {
          form_data,
          error_messages,
        };
      },
      () => this.checkPendingData()
    );
  };

  handleDescriptionChange = (content) => {
    this.setState(
      (prev_state) => {
        let error_messages = { ...prev_state.error_messages };
        const { t } = this.props;
        let description = [];
        if (content.replace(/(<([^>]+)>)/gi, "").length > 6000)
          description.push(
            `${t("general:length_of_text_limit__6000_characters")}`
          );
        error_messages = {
          ...error_messages,
          description,
        };

        return {
          ...prev_state,
          form_data: {
            ...prev_state.form_data,
            description: content,
          },
          error_messages,
        };
      },
      () => this.checkPendingData()
    );
  };

  handleToggleChange = (e, attr) => {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          [attr]: !prev_state.form_data[attr],
        },
      }),
      () => this.checkPendingData()
    );
  };

  handleLocationChange(e, value) {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: {
          ...prev_state.form_data,
          locations_selected_value: value,
        },
      }),
      () => this.checkPendingData()
    );
  }

  handleMemberChange(e, roleship_id) {
    e.preventDefault();
    this.setState(
      (prev_state) => {
        let roleships_selected_values =
          prev_state.form_data.roleships_selected_values;
        let index = roleships_selected_values.indexOf(roleship_id);

        if (index === -1) {
          roleships_selected_values.push(roleship_id);
        } else {
          roleships_selected_values.splice(index, 1);
        }
        return {
          ...prev_state,
          form_data: {
            ...prev_state.form_data,
            roleships_selected_values,
          },
        };
      },
      () => this.checkPendingData()
    );
  }

  //check pending data

  checkPendingData() {
    const { form_data, original_form_data } = this.state;
    const has_pending_data = !compare(
      JSON.parse(JSON.stringify(form_data)),
      original_form_data
    );
    this.setState({ has_pending_data });
  }

  acceptedValidateMethods(key, validationMethod) {
    this.validateMethods[key] = validationMethod;
  }

  validateAll = (methods = []) => {
    (methods || Object.keys(this.validateMethods)).map((key) =>
      this.validateMethods[key]()
    );

    // job functions checker
    const { t } = this.props;
    const countOfSelectedJobFunction = this.state.form_data
      .job_functions_selected_values.length;
    let job_functions_errors = [];
    if (countOfSelectedJobFunction <= 0)
      job_functions_errors.push(t("error_messages:required"));
    if (countOfSelectedJobFunction > 3)
      job_functions_errors.push(
        t("error_messages:selectMaximumLimit", { number: 3 })
      );
    if (job_functions_errors.length) {
      this.setState((prevState) => ({
        ...prevState,
        error_messages: {
          ...prevState.error_messages,
          job_functions: job_functions_errors,
        },
      }));
    }
  };

  hasErrors = () => {
    const { error_messages } = this.state;
    let hasErrors = false;
    const whiteList = ["full_message", "requiring_skills.skill"];
    for (let key in error_messages) {
      if (whiteList.indexOf(key) === -1 && error_messages[key].length)
        hasErrors = true;
    }
    return hasErrors;
  };

  // actions bar

  discard = (e) => {
    e.preventDefault();
    this.setState(
      (prev_state) => ({
        ...prev_state,
        form_data: JSON.parse(JSON.stringify(prev_state.original_form_data)),
        error_messages: {},
      }),
      () => this.checkPendingData()
    );
  };

  saveJob = async (e) => {
    e.preventDefault();
    await this.validateAll();
    if (this.hasErrors()) return null;

    const { active_mode, active_job, active_company_id } = this.state;
    this.setState({ publishing: true });
    let options = {
      method: "PUT",
      url: config.api_url(
        `/employers/${active_company_id}/jobs/${active_job.id}`
      ),
      headers: headers.getItemsFromLocalStorage("multipart/form-data"),
      data: await this.getFormData(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          const internal_uri = new InternalUri();
          this.setState(
            (prev_state) => ({
              ...prev_state,
              error_messages: {},
              publishing: false,
              loading: true,
            }),
            () => {
              switch (active_mode) {
                case "update":
                  this.props.history.push(
                    internal_uri.formatted_dashboard_jobs_page_path()
                  );
                  break;
                case "edit":
                default:
                  this.updateActiveCompany(this.state.active_company_id);
                  break;
              }
            }
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            publishing: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  postNewJob = async (e) => {
    e.preventDefault();
    await this.validateAll();
    if (this.hasErrors()) return null;

    const { active_company_id } = this.state;
    this.setState({ publishing: true });
    let options = {
      method: "POST",
      url: config.api_url(`/employers/${active_company_id}/jobs`),
      headers: headers.getItemsFromLocalStorage("multipart/form-data"),
      data: await this.getFormData(),
      json: true,
    };
    let new_job = {};
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          new_job = new Job(response.data);
          options = {
            method: "PUT",
            url: config.api_url(
              `/employers/${active_company_id}/jobs/${new_job.id}/state/published`
            ),
            headers: headers.getItemsFromLocalStorage(),
            json: true,
          };
          return axios(options);
        }
      })
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const { t } = this.props;
          const internal_uri = new InternalUri();
          this.props.history.push(
            internal_uri.formatted_dashboard_jobs_page_path()
          );
          this.props.addFlashMessage(
            true,
            t("flash_messages:job_post_published_successfully_"),
            "",
            "with-auto-disappear",
            ""
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            publishing: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  postJob = async (e) => {
    e.preventDefault();
    await this.validateAll();
    if (this.hasErrors()) return null;

    const { active_company_id, active_job } = this.state;
    this.setState({ publishing: true });
    let options = {
      method: "PUT",
      url: config.api_url(
        `/employers/${active_company_id}/jobs/${active_job.id}/state/published`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);

        if (this._isMounted) {
          const { t } = this.props;
          this.props.addFlashMessage(
            true,
            t("flash_messages:job_post_published_successfully_"),
            "",
            "with-auto-disappear",
            ""
          );
          this.saveJob(e);
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            publishing: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  saveNewDraft = async (e) => {
    e.preventDefault();
    await this.validateAll();
    if (this.hasErrors()) return null;

    const { active_company_id } = this.state;
    this.setState({ publishing: true });
    let options = {
      method: "POST",
      url: config.api_url(`/employers/${active_company_id}/jobs`),
      headers: headers.getItemsFromLocalStorage("multipart/form-data"),
      data: await this.getFormData(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        const internal_uri = new InternalUri();
        this.props.history.push(
          internal_uri.formatted_dashboard_jobs_page_path()
        );
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
            publishing: false,
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  getFormData = async () => {
    let formData = new FormData();
    const { form_data } = this.state;

    formData.append("job[title]", form_data.title);
    formData.append("job[work_type]", form_data.work_types_selected_value);
    formData.append(
      "job[contract_type]",
      form_data.contract_types_selected_value
    );
    formData.append("job[remote_working]", form_data.remote_checked);
    formData.append(
      "job[salary_currency]",
      form_data.currency_types_selected_value
    );
    formData.append("job[paid_period]", form_data.paid_periods_selected_value);
    formData.append(
      "job[minimum_salary]",
      removeCommasToNumber(form_data.minimum_salary)
    );
    formData.append(
      "job[maximum_salary]",
      removeCommasToNumber(form_data.maximum_salary)
    );
    if (form_data.locations_selected_value !== -1)
      formData.append(
        "job[job_addressing_attributes][address_id]",
        form_data.locations_selected_value
      );
    formData.append("job[description]", form_data.description);
    formData.append("job[plan_name]", form_data.plan_name);
    formData.append("job[salary_negotiable]", form_data.salary_negotiable);
    for (let i = 0; i < form_data.job_functions_selected_values.length; i++) {
      formData.append(
        `job[tag][job_functions][${i}][job_function_tag_id]`,
        form_data.job_functions_selected_values[i].value
      );
      formData.append(
        `job[tags][job_functions][${i}][job_function_tag_id]`,
        form_data.job_functions_selected_values[i].value
      );
    }
    for (
      let i = 0;
      i < form_data.requirement_skills_selected_values.length;
      i++
    ) {
      if (form_data.requirement_skills_selected_values[i].value < 0) {
        let new_requirement_skill_option = await this.createRequirementSkill(
          form_data.requirement_skills_selected_values[i].label
        );
        if (new_requirement_skill_option) {
          form_data.requirement_skills_selected_values[i] = {
            ...new_requirement_skill_option,
          };
          formData.append(
            `job[requiring_skills_attributes][${i}][skill_id]`,
            new_requirement_skill_option.value
          );
        }
      } else {
        formData.append(
          `job[requiring_skills_attributes][${i}][skill_id]`,
          form_data.requirement_skills_selected_values[i].value
        );
      }
    }
    this.setState((prev_state) => ({
      ...prev_state,
      form_data,
    }));
    for (let i = 0; i < form_data.roleships_selected_values.length; i++) {
      formData.append(
        `job[job_roleships_attributes][${i}][employer_roleship_id]`,
        form_data.roleships_selected_values[i]
      );
    }

    return formData;
  };

  createRequirementSkill = (new_skill_name) => {
    let formData = new FormData();
    formData.append("skill[name]", new_skill_name);
    let options = {
      method: "POST",
      url: config.api_url("/job_meta/requirement_skills"),
      headers: headers.getItemsFromLocalStorage("multipart/form-data"),
      data: formData,
      json: true,
    };
    return axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        const raw_requirment_skill = response.data;
        return {
          value: raw_requirment_skill.id,
          label: raw_requirment_skill.name,
        };
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }
        }

        return null;
      });
  };

  render() {
    const {
      creator_user_id,
      access_control_list,
      form_data,
      options,
      has_pending_data,
      active_mode,
      loading,
      publishing,
      error_messages,
      active_job,
      showJobFunctions,
      companyAddresses,
    } = this.state;
    const {
      handleSelectChange,
      handleMultiSelectChange,
      handleSkillsInputChange,
      handleInputChange,
      handleDescriptionChange,
      handleToggleChange,
      handleLocationChange,
      handleMemberChange,
      handleRadioChange,
      validateAll,
    } = this;
    const { discard, saveJob, postNewJob, postJob, saveNewDraft } = this;
    const { reduxTokenAuth } = this.props;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    if (loading) {
      return (
        <div className="loading-skeleton-wrapper">
          <div className="dashboard-job">
            <form>
              <div className="container-fluid">
                <h6 className="breadcrumb">
                  {active_mode === "edit" ? (
                    <Link
                      to={internal_uri.formatted_dashboard_jobs_page_path()}
                      className="btn btn-smallest btn-flat btn-hollow"
                    >
                      <img src={icon_arrow} alt="" />
                      <h5>{t("general:back")}</h5>
                    </Link>
                  ) : (
                    ""
                  )}
                  <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                    {t("general:home")}
                  </Link>
                  {" / "}
                  <Link to={internal_uri.formatted_dashboard_jobs_page_path()}>
                    {t("general:job_list")}
                  </Link>
                  {" / "}
                  {t(`general:${active_mode}`)}
                </h6>
                <h2>
                  {t(`general:${active_mode}`)}{" "}
                  <span className="job-title">
                    <span className="text text-160">{`${t(
                      "general:loading"
                    )}...`}</span>
                  </span>
                </h2>
                <h4 className="title-outside">{t("job_information")}</h4>
                <div className="block">
                  <div className="job-info-inner-wrapper">
                    <label>
                      <h5>{t("job_title")}</h5>
                    </label>
                    <div className="form-row">
                      <input className="input" type="text" />
                    </div>

                    <label>
                      <h5>{t("job_type")}</h5>
                    </label>
                    <div className="form-row form-row-2">
                      <div className="form-col">
                        <div className="select-wrapper">
                          <Select className="drop-down drop-down-larger" />
                        </div>
                      </div>
                      <div className="form-col">
                        <div className="select-wrapper">
                          <Select className="drop-down drop-down-larger" />
                        </div>
                      </div>
                    </div>

                    <label>
                      <h5>{t("remote")}</h5>
                    </label>
                    <div className="form-row">
                      <label className="radio-switcher">
                        <p className="not-allowed-text text text-80">{`${t(
                          "general:loading"
                        )}...`}</p>
                      </label>
                    </div>

                    <label>
                      <h5>{t("salary")}</h5>
                    </label>
                    <div className="form-row form-row-2">
                      <div className="form-col">
                        <div className="form-row form-row-dire-col">
                          <div className="form-col">
                            <label>
                              <h5>{t("at_least")}</h5>
                            </label>
                            <input className="input" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="form-col">
                        <div className="form-row form-row-dire-col">
                          <div className="form-col">
                            <label>
                              <h5>{t("up_to")}</h5>
                            </label>
                            <input className="input" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <label>
                      <h5>{t("currency")}</h5>
                    </label>
                    <div className="form-row form-row-2">
                      <div className="form-col">
                        <div className="select-wrapper">
                          <Select className="drop-down drop-down-larger" />
                        </div>
                      </div>
                    </div>

                    <label>
                      <h5>{t("paid_by_per")}</h5>
                    </label>
                    <div className="form-row form-row-2">
                      <div className="form-col">
                        <div className="select-wrapper">
                          <Select className="drop-down drop-down-larger" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="location">
                      <h5>{t("location")}</h5>
                    </label>
                    <div className="form-row form-row-dire-col">
                      <label className="radio">
                        <p className="text text-240">{`${t(
                          "general:loading"
                        )}...`}</p>
                      </label>
                      <label className="radio">
                        <p className="text text-240">{`${t(
                          "general:loading"
                        )}...`}</p>
                      </label>
                      <label className="radio">
                        <p className="text text-240">{`${t(
                          "general:loading"
                        )}...`}</p>
                      </label>
                    </div>
                  </div>

                  <label htmlFor="skills" className="in-line">
                    <h5>{t("skills")}</h5>
                    <h6>{t("_separate_by_enter_to_create_multiple_items_")}</h6>
                  </label>
                  <div className="form-row">
                    <div className="select-wrapper requirement_skills">
                      <Select className="drop-down drop-down-larger" />
                    </div>
                  </div>

                  <label htmlFor="job-description" className="lines">
                    <h5>{t("job_description")}</h5>
                    <h6>
                      {t("general:e_g__")} Responsibilities / Requirements /
                      Welfare
                    </h6>
                  </label>
                  <div className="form-row form-row-dire-col">
                    <div className="form-col">
                      <Editor />
                    </div>
                  </div>
                </div>
                <div>
                  <h4 className="title-outside">{t("people_to_notify")}</h4>
                  <div className="block">
                    <label className="in-line">
                      <h5>{t("members")}</h5>
                      <h6>
                        {t(
                          "_select_members_for_receiving_candidate_s_information_you_can_manage_members_at_member_management_section_"
                        )}
                      </h6>
                    </label>
                    <div className="form-row form-row-dire-col">
                      <div className="members-wrapper">
                        <div className="members">
                          <div className="member">
                            <label className="checkbox disabled">
                              <h6 className="name text text-80">{`${t(
                                "general:loading"
                              )}...`}</h6>
                              <h6 className="email text text-160">{`${t(
                                "general:loading"
                              )}...`}</h6>
                              <span className="badge">{`${t(
                                "general:loading"
                              )}...`}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }

    if (!access_control_list.jobs_manageable) {
      return (
        <div className="dashboard-job">
          <div className="container-fluid">
            {t(
              "general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_"
            )}
          </div>
        </div>
      );
    }

    const has_errors = this.hasErrors();
    const selected_full_job = companyAddresses.find(
      (opt) => opt.id === form_data.locations_selected_value
    );
    const is_selected_taiwan_job = selected_full_job
      ? selected_full_job.handwriting_country === "Taiwan"
      : false;

    return (
      <div className="dashboard-job">
        <form>
          <div className="container-fluid">
            <h6 className="breadcrumb">
              {// update & reopen 的狀況不需要 back
              active_mode === "edit" ? (
                <Link
                  to={internal_uri.formatted_dashboard_jobs_page_path()}
                  className="btn btn-smallest btn-flat btn-hollow"
                >
                  <img src={icon_arrow} alt="" />
                  <h5>{t("general:back")}</h5>
                </Link>
              ) : (
                ""
              )}
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              <Link to={internal_uri.formatted_dashboard_jobs_page_path()}>
                {t("general:job_list")}
              </Link>
              {" / "}
              {active_mode === "edit" &&
              active_job.published_state_key === "draft"
                ? t("general:edit_draft")
                : t(`general:${active_mode}`)}
            </h6>
            <h2>
              {t(`general:${active_mode}`)}{" "}
              <span className="job-title">{active_job.title}</span>
            </h2>
            {active_job.published_at ? (
              <h6 className="post-on">
                {t("posted_on")}{" "}
                {moment(active_job.published_at).format("YYYY/MM/DD")}
              </h6>
            ) : (
              ""
            )}
            <h4 className="title-outside">{t("job_information")}</h4>
            <div className="block">
              {error_messages.full_message ? (
                <h6 className="message error-message spacer">
                  {error_messages.full_message}
                </h6>
              ) : (
                ""
              )}
              <div className="job-info-inner-wrapper">
                <div className="form-row">
                  <div className="form-col">
                    <InputField
                      labelContent={`<h5>${t("job_title")}</h5>`}
                      inputId="job-title"
                      inputClassName=""
                      inputValue={form_data.title}
                      inputPlaceholderText="e.g. Business Development"
                      handleChange={(value, errorMessages) =>
                        handleInputChange("title", value, errorMessages)
                      }
                      errorMessages={error_messages.title}
                      validations={[required]}
                      shareValidateMethods={(method) =>
                        this.acceptedValidateMethods("title", method)
                      }
                    />
                  </div>
                </div>

                <label htmlFor="">
                  <h5>{t("job_type")}</h5>
                </label>
                <div className="form-row form-row-2">
                  <div className="form-col">
                    <div className="select-wdrapper">
                      <Select
                        className="drop-down drop-down-larger drop-down-grey"
                        clearable={false}
                        searchable={true}
                        value={form_data.work_types_selected_value}
                        onChange={(value) =>
                          handleSelectChange("work_types_selected_value", value)
                        }
                        options={options.work_types}
                        valueRenderer={(option) => t(option.label)}
                        optionRenderer={(option) => t(option.label)}
                      />
                    </div>
                  </div>
                  <div className="form-col">
                    <div className="select-wrapper">
                      <Select
                        className="drop-down drop-down-larger drop-down-grey"
                        clearable={false}
                        searchable={true}
                        value={form_data.contract_types_selected_value}
                        onChange={(value) =>
                          handleSelectChange(
                            "contract_types_selected_value",
                            value
                          )
                        }
                        options={options.contract_types}
                        valueRenderer={(option) => t(option.label)}
                        optionRenderer={(option) => t(option.label)}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="">
                  <h5>{t("remote")}</h5>
                </label>
                <div className="form-row">
                  <input
                    className="radio-input"
                    type="radio"
                    id="remote"
                    name="remote"
                    checked={form_data.remote_checked}
                    readOnly={true}
                  />
                  <label
                    htmlFor="remote"
                    className="radio-switcher"
                    onClick={(e) => handleToggleChange(e, "remote_checked")}
                  >
                    <p className="not-allowed-text">{t("not_allowed")}</p>
                  </label>
                </div>

                <label htmlFor="">
                  <h5>{t("salary")}</h5>
                </label>
                <div className="form-row form-row-2">
                  <div className="form-col">
                    <InputField
                      labelContent={`<h5>${t("at_least")}</h5>`}
                      inputId="at-least"
                      inputClassName=""
                      inputValue={form_data.minimum_salary}
                      inputPlaceholderText="40,000"
                      handleChange={(value, errorMessages) =>
                        handleInputChange(
                          "minimum_salary",
                          value,
                          errorMessages
                        )
                      }
                      errorMessages={error_messages.minimum_salary}
                      validations={
                        form_data.salary_negotiable ? [] : [required, numeral]
                      }
                      format={salary}
                      shareValidateMethods={(method) =>
                        this.acceptedValidateMethods("minimum_salary", method)
                      }
                      disabled={!!form_data.salary_negotiable}
                    />
                  </div>
                  <div className="form-col">
                    <InputField
                      labelContent={`<h5>${t("up_to")}</h5>`}
                      inputId="up-to"
                      inputClassName=""
                      inputValue={form_data.maximum_salary}
                      inputPlaceholderText="100,000"
                      handleChange={(value, errorMessages) =>
                        handleInputChange(
                          "maximum_salary",
                          value,
                          errorMessages
                        )
                      }
                      errorMessages={error_messages.maximum_salary}
                      validations={form_data.salary_negotiable ? [] : [numeral]}
                      format={salary}
                      shareValidateMethods={(method) =>
                        this.acceptedValidateMethods("maximum_salary", method)
                      }
                      disabled={!!form_data.salary_negotiable}
                    />
                  </div>
                </div>

                <div
                  className="form-row form-row-2"
                  style={{
                    marginTop: -24,
                    opacity: is_selected_taiwan_job ? 1 : 0,
                  }}
                >
                  <input
                    id="salary_negotiable"
                    name="member"
                    checked={form_data.salary_negotiable}
                    type="checkbox"
                    className="checkbox-input"
                  />
                  <label
                    htmlFor="salary_negotiable"
                    className={`checkbox`}
                    onClick={async (e) => {
                      await handleToggleChange(e, "salary_negotiable");
                      // form_data.minimum_salary = "";
                      handleInputChange("minimum_salary", "", []);
                      handleInputChange("maximum_salary", "", []);

                      const currencyTwdOption = options.currency_types.find(
                        (item) => item.value === "TWD"
                      );
                      if (currencyTwdOption) {
                        handleSelectChange(
                          "currency_types_selected_value",
                          currencyTwdOption
                        );
                      }
                      handleSelectChange(
                        "paid_periods_selected_value",
                        options.paid_periods.find(
                          (item) => item.value === "monthly"
                        )
                      );

                      validateAll(["minimum_salary", "maximum_salary"]);
                    }}
                  >
                    <h6 className="name">{t("negotiable_description")}</h6>
                  </label>
                </div>

                <label htmlFor="">
                  <h5>{t("currency")}</h5>
                </label>
                <div className="form-row form-row-2">
                  <div className="form-col">
                    <div className="select-wrapper">
                      <Select
                        className="drop-down drop-down-larger drop-down-grey"
                        clearable={false}
                        searchable={true}
                        value={form_data.currency_types_selected_value}
                        onChange={(value) =>
                          handleSelectChange(
                            "currency_types_selected_value",
                            value
                          )
                        }
                        options={options.currency_types}
                        disabled={!!form_data.salary_negotiable}
                      />
                    </div>
                  </div>
                </div>

                <label htmlFor="">
                  <h5>{t("paid_by_per")}</h5>
                </label>
                <div className="form-row form-row-2">
                  <div className="form-col">
                    <div className="select-wrapper">
                      <Select
                        className="drop-down drop-down-larger drop-down-grey"
                        clearable={false}
                        searchable={true}
                        value={form_data.paid_periods_selected_value}
                        onChange={(value) =>
                          handleSelectChange(
                            "paid_periods_selected_value",
                            value
                          )
                        }
                        options={options.paid_periods}
                        valueRenderer={(option) => t(option.label)}
                        optionRenderer={(option) => t(option.label)}
                        disabled={!!form_data.salary_negotiable}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {options.locations.length > 0 ? (
                <div className="locations-wrapper">
                  <label htmlFor="location">
                    <h5>{t("location")}</h5>
                  </label>
                  <div className="form-row form-row-dire-col">
                    {options.locations.map((location, i) => (
                      <div key={i}>
                        <input
                          name="location"
                          className="radio-input"
                          type="radio"
                          value={location.value}
                          id={location.value}
                          checked={
                            form_data.locations_selected_value ===
                            location.value
                          }
                          readOnly
                        />
                        <label
                          htmlFor={location.value}
                          className="radio"
                          onClick={(e) => {
                            handleLocationChange(e, location.value);
                            const selected_full_job = companyAddresses.find(
                              (opt) => opt.id === location.value
                            );
                            const is_selected_taiwan_job = selected_full_job
                              ? selected_full_job.handwriting_country ===
                                "Taiwan"
                              : false;
                            form_data.salary_negotiable = is_selected_taiwan_job
                              ? form_data.salary_negotiable
                              : false;
                            setTimeout(() => {
                              validateAll(["minimum_salary"]);
                            }, 0);
                          }}
                        >
                          <p>{location.label}</p>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}

              <label htmlFor="job_function" className="in-line">
                <h5>{t("general:job_function")}</h5>
                <h6>{t("_select_up_to_3_")}</h6>
              </label>
              <div className="job-function-row">
                {error_messages.job_functions &&
                error_messages.job_functions.length ? (
                  <h6 className="message error-message">
                    {error_messages.job_functions.join(", ")}
                  </h6>
                ) : (
                  ""
                )}
                <div className="job-function-col">
                  {form_data.job_functions_selected_values.length ? (
                    <div className="job-function-badges">
                      {form_data.job_functions_selected_values.map((_jFn) => (
                        <div
                          className="badge badge-hollow badge-blue badge-job-function"
                          key={`jfn-badge-${_jFn.value}`}
                        >
                          {t(_jFn.label)}
                          <span
                            onClick={() =>
                              this.remove_job_functions_selected_values(_jFn)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <button
                      className="btn btn-larger btn-flat btn-fill"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleJobFunctionShow(true);
                      }}
                    >
                      <h5>{t("select_job_functions")}</h5>
                    </button>
                  </div>
                  <h6 className="extra-description">
                    <Interpolate
                      i18nKey="noSuitableJobFunctionContactInfo"
                      useDangerouslySetInnerHTML={true}
                      contact_us={
                        <a
                          className="link"
                          href="/#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.updateContactMeetJobsModal(true);
                          }}
                        >
                          {t("contact_us")}
                        </a>
                      }
                    />
                  </h6>
                </div>
              </div>

              <label htmlFor="skills" className="in-line">
                <h5>{t("skills")}</h5>
                <h6>{t("_separate_by_enter_to_create_multiple_items_")}</h6>
              </label>
              <div className="form-row">
                <div className="select-wrapper requirement_skills">
                  <Select
                    className="drop-down drop-down-larger drop-down-grey"
                    multi={true}
                    clearable={true}
                    searchable={true}
                    onInputChange={(value) => handleSkillsInputChange(value)}
                    onChange={(value) =>
                      handleMultiSelectChange(
                        "requirement_skills_selected_values",
                        value
                      )
                    }
                    value={form_data.requirement_skills_selected_values}
                    options={options.requirement_skills}
                  />
                </div>
              </div>

              <label htmlFor="job-description" className="lines">
                <h5>{t("job_description")}</h5>
                <h6>
                  {t("general:e_g__")} Responsibilities / Requirements / Welfare
                </h6>
              </label>
              <div className="form-row form-row-dire-col">
                <div className="form-col">
                  <Editor
                    content={form_data.description}
                    className={
                      error_messages.description &&
                      error_messages.description.length
                        ? "input-danger"
                        : ""
                    }
                    updateContent={(value) => handleDescriptionChange(value)}
                  />
                </div>
                <div className="form-col">
                  {error_messages.description &&
                  error_messages.description.length ? (
                    <h6 className="message error-message">
                      {error_messages.description.join(", ")}
                    </h6>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="jobs-plans-wrapper">
              <h4 className="title-outside">{t("general:select_plan")}</h4>
              <div className="block">
                <div className="jobs-plans ">
                  <div className="plan-wrapper">
                    <input
                      name="plan"
                      checked={form_data.plan_name === "custom_referral"}
                      type="radio"
                      className="radio-input"
                      readOnly
                    />
                    <label
                      htmlFor="custom_referral"
                      className="radio-plan"
                      // onClick={(e) =>
                      //   handleRadioChange(e, "plan_name", "custom_referral")
                      // }
                    >
                      <div className="block plan plan-custom">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${plan_hire_plus})`,
                          }}
                        />
                        <div className="info clearfix">
                          <h4>{t("general:plan_hire_")}</h4>
                          <h3>{t("general:_20__annual_salary")}</h3>
                          <h5>{t("general:per_successful_hire")}</h5>
                        </div>
                      </div>
                    </label>
                  </div>

                  <div className="plan-wrapper">
                    <input
                      name="plan"
                      checked={form_data.plan_name === "social_referral"}
                      type="radio"
                      className="radio-input"
                      readOnly
                    />
                    <label
                      htmlFor="social_referral"
                      className="radio-plan"
                      // onClick={(e) =>
                      //   handleRadioChange(e, "plan_name", "social_referral")
                      // }
                    >
                      <div className="block plan plan-hire">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${plan_hire})`,
                          }}
                        ></div>
                        <div className="info">
                          <h4>{t("general:plan_hire")}</h4>
                          <h3>{t("general:_16__annual_salary")}</h3>
                          <h5>{t("general:per_successful_hire")}</h5>
                        </div>
                      </div>
                    </label>
                  </div>

                  <div className="plan-wrapper">
                    <input
                      name="plan"
                      checked={form_data.plan_name === "post_only"}
                      type="radio"
                      className="radio-input"
                      readOnly
                    />
                    <label
                      htmlFor="post_only"
                      className="radio-plan"
                      // onClick={(e) =>
                      //   handleRadioChange(e, "plan_name", "post_only")
                      // }
                    >
                      <div className="block plan plan-post">
                        <div
                          className="image-wrapper"
                          style={{
                            backgroundImage: `url(${plan_post})`,
                          }}
                        ></div>
                        <div className="info">
                          <h4>{t("general:plan_post")}</h4>
                          <h3>
                            <del style={{ marginRight: 8 }}>
                              {t("general:_175__usd_cost")}
                            </del>
                            <strong>{t("general:_175__usd_free")}</strong>
                          </h3>
                          <h5>{t("general:per_month_subscription_fee")}</h5>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <br />

                <Interpolate
                  i18nKey="if_you_have_further_questions__please___meet_jobs_"
                  useDangerouslySetInnerHTML={true}
                  contact={
                    <button
                      className="link"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.updateContactMeetJobsModal(true);
                      }}
                    >
                      {t("contact")}
                    </button>
                  }
                />
              </div>
            </div>

            {options.roleships.length > 0 ? (
              <div>
                <h4 className="title-outside">{t("people_to_notify")}</h4>
                <div className="block">
                  <label className="in-line">
                    <h5>{t("members")}</h5>
                    <h6>
                      {t(
                        "_select_members_for_receiving_candidate_s_information_you_can_manage_members_at_member_management_section_"
                      )}
                    </h6>
                  </label>
                  <div className="form-row form-row-dire-col">
                    <div className="members-wrapper">
                      <div className="members">
                        {options.roleships.map((member, i) => (
                          <div className="member" key={i}>
                            <input
                              id={`member_${i}`}
                              name="member"
                              checked={
                                form_data.roleships_selected_values.indexOf(
                                  member.roleship_id
                                ) !== -1
                              }
                              type="checkbox"
                              className="checkbox-input"
                              readOnly={true}
                            />
                            {creator_user_id === member.user_id ? (
                              <label
                                htmlFor={`member_${i}`}
                                className={`checkbox disabled`}
                              >
                                <h6 className="name">
                                  {member.title}
                                  {reduxTokenAuth.currentUser.attributes.id ===
                                  member.user_id
                                    ? " (You)"
                                    : ""}
                                </h6>
                                <h6 className="email">({member.email})</h6>
                                <span className={`badge ${member.color}`}>
                                  {member.role_name}
                                </span>
                              </label>
                            ) : (
                              <label
                                htmlFor={`member_${i}`}
                                className={`checkbox`}
                                onClick={(e) =>
                                  handleMemberChange(e, member.roleship_id)
                                }
                              >
                                <h6 className="name">
                                  {member.title}
                                  {reduxTokenAuth.currentUser.attributes.id ===
                                  member.user_id
                                    ? " (You)"
                                    : ""}
                                </h6>
                                <h6 className="email">({member.email})</h6>
                                <span className={`badge ${member.color}`}>
                                  {member.role_name}
                                </span>
                              </label>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <JobFunctionModal
            handleJobFunctionsInputChange={this.handleJobFunctionsInputChange}
            error={
              error_messages.job_functions &&
              error_messages.job_functions.length
                ? error_messages.job_functions.join(", ")
                : ""
            }
            group={jobFunctionGroup}
            jobFunctions={options.job_functions}
            form_data={form_data}
            t={t}
            showJobFunctions={showJobFunctions}
            handleJobFunctionShow={this.handleJobFunctionShow}
          />
        </form>

        <CSSTransitionGroup
          component="div"
          transitionName="actions-bar-slide"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={100}
        >
          {active_mode === "reopen" ||
          active_mode === "update" ||
          (active_mode === "edit" &&
            active_job.published_state_key === "draft") ||
          has_pending_data ? (
            <div className="actions-bar-wrapper">
              <div className="actions-bar">
                <p>
                  {publishing
                    ? `${t("general:publishing")}...`
                    : active_mode === "edit"
                    ? active_job.published_state_key === "draft" &&
                      !has_pending_data
                      ? t("to_post_your_job__click_post_")
                      : t("to_save_your_changes__click_save_")
                    : active_mode === "reopen"
                    ? t(
                        "we_will_create_a_new_job_post_with_the_same_content_and_keep_the_old_one_for_the_record_"
                      )
                    : t("update_your_job_post_to_extend_for_60_more_days_")}
                </p>
                <div className="buttons-wrapper">
                  {/* 右三 */}
                  {active_mode === "reopen" ||
                  (active_mode === "edit" &&
                    active_job.published_state_key === "draft") ? (
                    publishing ? (
                      <a
                        href="/#"
                        onClick={(e) => e.preventDefault()}
                        className="link-white link-white-pc disabled"
                      >
                        <h5>{t("general:cancel")}</h5>
                      </a>
                    ) : (
                      <Link
                        className="link-white link-white-pc"
                        to={internal_uri.formatted_dashboard_jobs_page_path()}
                      >
                        <h5>{t("general:cancel")}</h5>
                      </Link>
                    )
                  ) : (
                    ""
                  )}

                  {/* 右二 */}
                  {active_mode === "edit" ? (
                    active_job.published_state_key === "draft" ? (
                      publishing || has_errors || !has_pending_data ? (
                        <button
                          onClick={(e) => e.preventDefault()}
                          className="btn btn-larger btn-flat btn-fill disabled"
                        >
                          <h5>{t("general:save_draft")}</h5>
                        </button>
                      ) : (
                        <button
                          className="btn btn-larger btn-flat btn-fill"
                          onClick={(e) => saveJob(e)}
                        >
                          <h5>{t("general:save_draft")}</h5>
                        </button>
                      )
                    ) : publishing ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-fill disabled"
                      >
                        <h5>{t("general:cancel")}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => discard(e)}
                      >
                        <h5>{t("general:cancel")}</h5>
                      </button>
                    )
                  ) : (
                    ""
                  )}
                  {active_mode === "update" ? (
                    publishing ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-fill disabled"
                      >
                        <h5>{t("general:cancel")}</h5>
                      </button>
                    ) : (
                      <Link
                        className="btn btn-larger btn-flat btn-fill"
                        to={internal_uri.formatted_dashboard_jobs_page_path()}
                      >
                        <h5>{t("general:cancel")}</h5>
                      </Link>
                    )
                  ) : (
                    ""
                  )}
                  {active_mode === "reopen" ? (
                    publishing ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-fill disabled"
                      >
                        <h5>{t("general:save_draft")}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-fill"
                        onClick={(e) => saveNewDraft(e)}
                      >
                        <h5>{t("general:save_draft")}</h5>
                      </button>
                    )
                  ) : (
                    ""
                  )}

                  {/* 右一 */}
                  {active_mode === "edit" ? (
                    active_job.published_state_key === "draft" ? (
                      publishing || has_errors ? (
                        <button
                          onClick={(e) => e.preventDefault()}
                          className="btn btn-larger btn-flat btn-hollow disabled"
                        >
                          <h5>{t(`general:post`)}</h5>
                        </button>
                      ) : (
                        <button
                          className="btn btn-larger btn-flat btn-hollow"
                          onClick={(e) => postJob(e)}
                        >
                          <h5>{t(`general:post`)}</h5>
                        </button>
                      )
                    ) : publishing || has_errors ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-hollow disabled"
                      >
                        <h5>{t(`general:save`)}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-hollow"
                        onClick={(e) => saveJob(e)}
                      >
                        <h5>{t(`general:save`)}</h5>
                      </button>
                    )
                  ) : (
                    ""
                  )}
                  {active_mode === "update" ? (
                    publishing || has_errors ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-hollow disabled"
                      >
                        <h5>{t(`general:update`)}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-hollow"
                        onClick={(e) => saveJob(e)}
                      >
                        <h5>{t(`general:update`)}</h5>
                      </button>
                    )
                  ) : (
                    ""
                  )}
                  {active_mode === "reopen" ? (
                    publishing || has_errors ? (
                      <button
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-larger btn-flat btn-hollow disabled"
                      >
                        <h5>{t(`general:post`)}</h5>
                      </button>
                    ) : (
                      <button
                        className="btn btn-larger btn-flat btn-hollow"
                        onClick={(e) => postNewJob(e)}
                      >
                        <h5>{t(`general:post`)}</h5>
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
                {active_mode === "reopen" ||
                (active_mode === "edit" &&
                  active_job.published_state_key === "draft") ? (
                  publishing ? (
                    <a
                      href="/#"
                      className="link-white link-white-mb disabled"
                      onClick={(e) => e.preventDefault()}
                    >
                      <h5>{t("general:cancel")}</h5>
                    </a>
                  ) : (
                    <Link
                      className="link-white link-white-mb"
                      to={internal_uri.formatted_dashboard_jobs_page_path()}
                    >
                      <h5>{t("general:cancel")}</h5>
                    </Link>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default withNamespaces([
  "dashboard_job_page",
  "general",
  "states",
  "error_messages",
])(JobPage);
