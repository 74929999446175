export default {
  posted_on: "Posted on",
  job_information: "Job information",
  job_title: "Job title",
  job_type: "Job type",
  remote: "Remote",
  not_allowed: "Not allowed",
  salary: "Salary",
  at_least: "At least",
  up_to: "Up to",
  negotiable_description:
    "Negotiable (Regular monthly salary above 40,000 TWD)",
  currency: "Currency",
  paid_by_per: "Paid by per",
  location: "Location",
  _select_up_to_3_: "(Select up to 3)",
  noSuitableJobFunctionContactInfo:
    "If you cannot find a suitable job function, please feel free to {{contact_us}}.",
  contact_us: "contact us",
  skills: "Skills",
  _separate_by_enter_to_create_multiple_items_:
    "(Separate by enter to create multiple items)",
  job_description: "Job description",
  people_to_notify: "People to notify",
  members: "Members",
  _select_members_for_receiving_candidate_s_information_you_can_manage_members_at_member_management_section_:
    "(Select members for receiving candidate's information, you can manage members at Member management section)",
  to_save_your_changes__click_save_: "To save your changes, click Save.",
  to_post_your_job__click_post_: "To post your job, click Post.",
  update_your_job_post_to_extend_for_60_more_days_:
    "Update your job post to extend for 60 more days.",
  more_detail_will_help_candidates_understand_your_need_for_this_position_:
    "More details will help candidates understand your need for this position.",
  post: "Post",
  posting: "Posting",
  we_will_create_a_new_job_post_with_the_same_content_and_keep_the_old_one_for_the_record_:
    "We will create a new job post with the same content and keep the old one for the record.",
  apply: "apply",
  select_job_functions: "Select job functions",
  if_you_have_further_questions__please___meet_jobs_:
    "If you have further questions, please {{contact}} Meet.jobs.",
};
