import Abstract from "./abstract.js";
import lookup from "country-code-lookup";
import moment from "moment/moment.js";
import i18n from "./../../i18n";
import JobApplication from "./job_application.js";
import default_logo from "./../../assets/images/image_default_logo.svg";
import Employer from "./employer.js";
import Event from "./event.js";
import {
  contain_not_only_whitespace,
  removeHtmlTags,
} from "../../assets/js/functions";
import JobFunction from "./job_function";

class Job extends Abstract {
  constructor(attrs) {
    super(attrs);

    let raw_employer = attrs.employer;
    if (raw_employer) {
      delete attrs["employer"];
      this._build_association({
        name: "employer",
        klass: Employer,
        attrs: raw_employer,
      });
    }

    let raw_job_applications = attrs.job_applications;
    if (raw_job_applications) {
      delete attrs["job_applications"];
      this._build_associations({
        name: "job_applications",
        klass: JobApplication,
        attrs: raw_job_applications,
      });
    }

    let raw_referral_job_applications = attrs.referral_job_applications;
    if (raw_referral_job_applications) {
      delete attrs["referral_job_applications"];
      this._build_associations({
        name: "referral_job_applications",
        klass: JobApplication,
        attrs: raw_referral_job_applications,
      });
    }

    let raw_events = attrs.events;
    if (raw_events) {
      delete attrs["events"];
      this._build_associations({
        name: "events",
        klass: Event,
        attrs: raw_events,
      });
    }

    let raw_job_functions = attrs.job_functions;
    if (raw_job_functions) {
      delete attrs["job_functions"];
      this._build_associations({
        name: "job_functions",
        klass: JobFunction,
        attrs: raw_job_functions,
      });
    }
  }

  static shortcut_for_money(money) {
    if (money >= 1000000000) {
      return Math.round((money / 1000000000) * 100) / 100 + "b";
    } else if (money >= 1000000) {
      return Math.round((money / 1000000) * 100) / 100 + "m";
    } else if (money >= 1000) {
      return Math.round((money / 1000) * 100) / 100 + "k";
    }
    return Math.round(money * 100) / 100;
  }

  formatted_title() {
    return this.title ? this.title : "(not provided)";
  }

  formatted_form_title() {
    return this.title ? this.title : "";
  }

  has_description() {
    return contain_not_only_whitespace(removeHtmlTags(this.description));
  }

  has_salary() {
    if (!this.salary) return false;
    if (Object.keys(this.salary).length === 0) return false;
    if (this.salary.minimum !== null || this.salary.maximum !== null)
      return true;
    else return false;
  }

  formatted_salary_range() {
    if (this.has_salary) {
      if (this.salary.maximum === null) {
        // if maximum === 0, not show!
        let minimum_salary = this.constructor.shortcut_for_money(
          this.salary.minimum
        );
        return minimum_salary + "+";
      }

      if (this.salary.minimum === this.salary.maximum) {
        let minimum_salary = this.constructor.shortcut_for_money(
          this.salary.minimum
        );
        return minimum_salary;
      } else {
        let minimum_salary = this.constructor.shortcut_for_money(
          this.salary.minimum
        );
        let maximum_salary = this.constructor.shortcut_for_money(
          this.salary.maximum
        );
        return `${minimum_salary} - ${maximum_salary}`;
      }
    }
    return "";
  }

  formatted_salary_currency() {
    return this.has_salary() ? this.salary.currency : "USD";
  }

  formatted_salary_paid_period() {
    return this.has_salary()
      ? ` / ${i18n.t(`states:${this.salary.paid_period_key}`).toLowerCase()}`
      : "";
  }

  formatted_reward_number() {
    // paid_period 有四種 ‘annually’, ‘monthly’, ‘daily’, ‘hourly’
    // 月薪：一半  年薪：4%  時薪：80倍  日薪：10倍
    const reward =
      this.salary.minimum !== null ? this.salary.minimum : this.salary.maximum;
    if (!reward) return "0+";

    switch (this.salary.paid_period_key) {
      case "annually":
        return `${this.constructor.shortcut_for_money((reward * 4) / 100)}`;
      case "monthly":
        return `${this.constructor.shortcut_for_money(
          (reward * 12 * 4) / 100
        )}`;
      case "daily":
        return `${this.constructor.shortcut_for_money(
          (reward * 20 * 12 * 4) / 100
        )}`;
      case "hourly":
        return `${this.constructor.shortcut_for_money(
          (reward * 160 * 12 * 4) / 100
        )}`;
      default:
        return "";
    }
  }

  formatted_reward() {
    return `${this.formatted_reward_number()} ${this.formatted_salary_currency()}`;
  }

  formatted_past_time(t) {
    const the_time = this.updated_at;
    if (the_time) {
      const now_time = moment(Date.now());
      const time_by_minute = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "minutes", true))
      );
      const time_by_hour = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "hours", true))
      );
      const time_by_day = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "days", true))
      );

      if (time_by_minute <= 1 && time_by_minute >= 0) {
        return time_by_minute + t("general:_minute_ago");
      } else if (time_by_hour < 1 && time_by_hour >= 0) {
        return time_by_minute + t("general:_minutes_ago");
      } else if (time_by_hour === 1) {
        return time_by_hour + t("general:_hour_ago");
      } else if (time_by_day < 1 && time_by_day >= 0) {
        return time_by_hour + t("general:_hours_ago");
      } else if (time_by_day === 1) {
        return time_by_day + t("general:_day_ago");
      } else {
        return time_by_day + t("general:_days_ago");
      }

      // return moment(the_time).format('YYYY/MM/DD HH:mm');
    }
    return "";
  }

  formatted_shortcut_for_past_time() {
    const the_time = this.updated_at;
    if (the_time) {
      const now_time = moment(Date.now());
      const time_by_hour = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "hours", true))
      );
      const time_by_day = Math.floor(
        parseFloat(moment(now_time).diff(moment(the_time), "days", true))
      );

      if (time_by_day < 1 && time_by_day >= 0) {
        return time_by_hour + "h";
      } else {
        return time_by_day + "d";
      }
    }
    return "";
  }

  formatted_primary_location() {
    if (!this.address) return i18n.t("general:_not_provided_");
    // if(this.address.place) {
    //     if(this.address.place.country || this.address.place.city || this.address.place.state) {
    //         let location = '';
    //         if(this.address.place.city) location += this.address.place.city;
    //         else if(this.address.place.state) location += this.address.place.state;
    //         if(this.address.place.country && (this.address.place.city || this.address.place.state)) location += ', ';
    //         if(this.address.place.country) location += this.address.place.country;
    //         return location;
    //     }
    // }
    if (this.address.handwriting_country || this.address.handwriting_city) {
      let location = "";
      if (this.address.handwriting_city)
        location += this.address.handwriting_city;
      if (this.address.handwriting_country && this.address.handwriting_city)
        location += ", ";
      if (this.address.handwriting_country)
        location += this.address.handwriting_country;
      return location;
    }
    return i18n.t("general:_not_provided_");
  }

  has_place() {
    if (!this.address) return false;
    if (!this.address.place) return false;
    return true;
  }

  get country_code() {
    if (!this.has_place()) return null;
    const country = lookup.byCountry(this.address.place.country);
    return country ? country.fips : this.address.place.country;
  }

  formatted_work_type(t) {
    // if(!this.contract) return t('general:_not_provided_');
    return this.work_type_key
      ? t(`states:${this.work_type_key}`)
      : t("general:_not_provided_");
  }

  formatted_contract_type(t) {
    // if(!this.contract) return t('general:_not_provided_');
    return this.contract_type_key
      ? t(`states:${this.contract_type_key}`)
      : t("general:_not_provided_");
  }

  is_negotiable() {
    return this.has_salary() ? this.salary.negotiable : false;
  }

  is_remoteable() {
    if (!this.contract) return false;
    return this.contract.remote_working;
  }

  is_closed() {
    if (this.published_state_key === "closed") return true;
    return false;
  }

  is_draft() {
    if (this.published_state_key === "draft") return true;
    return false;
  }

  //employer
  has_employer() {
    return this.employer !== null && typeof this.employer !== "undefined";
  }

  formatted_employer_name() {
    if (this.has_employer())
      return this.employer.name ? this.employer.name : "(not provided)";
    else
      return this.external_employer_name
        ? this.external_employer_name
        : "(not provided)";
  }

  formatted_external_employer_logo_url() {
    return this.external_employer_logo_url
      ? this.external_employer_logo_url
      : default_logo;
  }

  has_employer_slogan() {
    if (!this.employer) return false;
    return contain_not_only_whitespace(this.employer.slogan);
  }

  get_employer_id() {
    // TODO: 和 Bruce 確定架構
    return this.employer ? this.employer.id : null;
  }

  is_referralable() {
    return this.referralable;
  }

  // current user status
  is_current_user_ever_applied() {
    if (
      this.current_user_status === null ||
      typeof this.current_user_status === "undefined"
    )
      return false;
    if (this.current_user_status.applied && this.current_user_status.applied_at)
      return true;
    return false;
  }

  formatted_current_user_applied_time() {
    // TODO: momentjs 的設定應該要改為全域設定
    moment.updateLocale("zh-tw", {
      longDateFormat: {
        LL: " YYYY 年 M 月 D 日",
      },
    });
    return moment(this.current_user_status.applied_at)
      .locale(i18n.language)
      .format("LL");
  }

  has_event() {
    if (!this.events) return false;
    if (this.events.length <= 0) return false;
    return true;
  }
}

export default Job;
