import React from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment/moment";
import Job from "../../../models/job";
import Config from "../../../models/config.js";
import ApiHeaders from "../../../models/api_headers";
import axios from "axios";
import Pagination from "../../general/Pagination";
import metas from "../../../models/metas.js";
import { withNamespaces } from "react-i18next";
import blank_job from "../../../../assets/images/i027-blank_post.svg";
import { page_smothly_scroll_to } from "../../../../assets/js/functions";
import { CSSTransitionGroup } from "react-transition-group";
import InternalUri from "../../../models/internal_uri";
import modal_pop_up_delete from "../../../../assets/images/i047-pop-ups-delete.svg";
import modal_pop_up_close from "../../../../assets/images/i048-pop-ups-cancel.svg";
import { ReactComponent as IconSpeedInterview } from "../../../../assets/images/icon/speed_interview.svg";

const config = new Config();
const headers = new ApiHeaders();

class JobsPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.updateActiveCompany = this.updateActiveCompany.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleUpdatable = this.handleUpdatable.bind(this);
    this.postJob = this.postJob.bind(this);
    this.continuePostJob = this.continuePostJob.bind(this);
    this.closeJob = this.closeJob.bind(this);
    this.continueCloseJob = this.continueCloseJob.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.continueDeleteJob = this.continueDeleteJob.bind(this);
    this.editJob = this.editJob.bind(this);
    this.reopenJob = this.reopenJob.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.updateAllJobs = this.updateAllJobs.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.state = {
      plans_selected_value: "",
      active_company_id: -1,
      selectedOption: "",
      data: {},
      active_tab_index: 0,
      change_state_message_modal: {
        open: false,
        text: "",
        action_type: "",
        the_action: "",
      },
      access_control_list: {},
      published_states: {},
      error_messages: {},
      loading: true,
    };
    this.defaultParameter = {
      paginator: { current_page: 1 },
      criteria: { name: "updated_at", sortBy: "desc" },
    };
    this.parameters = {};
  }

  componentDidMount() {
    this._isMounted = true;

    // TODO: published_states 需要等 Bruce 把相關 api 補上 all: "全部" 的選項，目前先模擬
    let published_states = new metas("published_states", {
      published: "已發佈",
      draft: "草稿",
      closed: "已關閉",
      all: "全部",
    });

    // init parameters
    const { parameters } = this;
    Object.keys(published_states).map(
      (key) =>
        (parameters[key] = JSON.parse(JSON.stringify(this.defaultParameter)))
    );

    const { current_user } = this.props;
    this.setState(
      (prev_state) => ({
        ...prev_state,
        published_states,
        access_control_list:
          current_user.employer_roles[current_user.active_company.id]
            .access_control_list,
      }),
      () => this.updateActiveCompany(current_user.active_company.id)
    );
  }

  UNSAFE_componentWillReceiveProps(next_props) {
    if (
      this.props.current_user.active_company.id !==
      next_props.current_user.active_company.id
    ) {
      this.updateActiveCompany(next_props.current_user.active_company.id);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateActiveCompany(company_id) {
    const { access_control_list } = this.state;

    if (access_control_list.jobs_manageable) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          active_company_id: company_id,
          loading: true,
        }),
        () => this.fetchJobs(Object.keys(this.state.published_states))
      );
    } else {
      this.setState((prev_state) => ({
        ...prev_state,
        active_company_id: company_id,
        loading: false,
      }));
    }
  }

  fetchJobs(keys) {
    const { active_company_id } = this.state;
    let options;
    let promises = [];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const { paginator, criteria } = this.parameters[key];
      options = {
        method: "GET",
        url: config.api_url(
          `/employers/${active_company_id}/jobs/state/${key}?page=${paginator.current_page}&include=job_applications_count&criteria[0][name]=${criteria.name}&criteria[0][sort_by]=${criteria.sortBy}&plan_name=${this.state.plans_selected_value}`
        ),
        headers: headers.getItemsFromLocalStorage(),
        json: true,
      };
      promises.push(axios(options));
    }

    this.setState({ loading: true });

    // options = {
    //   method: "GET",
    //   url: config.api_url(`/employers/${active_company_id}/plans`),
    //   headers: headers.getItemsFromLocalStorage(),
    //   json: true,
    // };
    // promises.push(axios(options));
    // options = {
    //   method: "GET",
    //   url: config.api_url_v2(`/employers/${active_company_id}/jobs`),
    //   headers: headers.getItemsFromLocalStorage(),
    //   json: true,
    // };
    // promises.push(axios(options));

    axios
      .all(promises)
      .then((results) => {
        for (let i = 0; i < results.length; i++) {
          headers.updateItemsToLocalStorage(results[i].headers);
        }
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          this.setState((prev_state) => {
            let data = prev_state.data;
            for (let i = 0; i < results.length; i++) {
              const key = keys[i];
              const raw_data = results[i].data;
              this.parameters[key].paginator = raw_data.paginator;
              let jobs = [],
                job;
              if (raw_data.collection.length) {
                for (let i = 0; i < raw_data.collection.length; i++) {
                  job = new Job(raw_data.collection[i]);
                  jobs.push({ job: JSON.stringify(job) });
                }
              }
              data = {
                ...data,
                [key]: jobs,
              };
            }

            return {
              ...prev_state,
              data,
              change_state_message_modal: {
                open: false,
                text: "",
                action_type: "",
                the_action: "",
                job_id: -1,
              },
              loading: false,
              error_messages: {},
            };
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  handleSelectChange = (property_name, selected_option) => {
    if (selected_option) {
      this.setState(
        (prev_state) => ({
          ...prev_state,
          [property_name]: selected_option.value,
        }),
        () => {
          this.fetchJobs(["all", "draft", "published", "closed"]);
        }
      );
    }
  };

  handleUpdatable = (job_id, start_time) => {
    const { t } = this.props;
    const due_time = moment(start_time)
      .add(60, "days")
      .format("YYYY/MM/DD");
    const days_left = parseInt(
      moment(due_time)
        .add(1, "days")
        .diff(moment(Date.now()), "days"),
      10
    );

    if (days_left > 30) {
      return null;
    } else if (days_left > 1 && days_left <= 30) {
      return (
        <h5>
          <span className="badge badge-yellow">
            {t("expire_in___days", { days_left: days_left })}
          </span>
          <a
            href="/#"
            className="link"
            onClick={(e) => this.updateJob(e, job_id)}
          >
            {t("extend")}
          </a>
        </h5>
      );
    } else if (days_left === 1) {
      return (
        <h5>
          <span className="badge badge-yellow">{t("expire_in_1_day")}</span>
          <a
            href="/#"
            className="link"
            onClick={(e) => this.updateJob(e, job_id)}
          >
            {t("extend")}
          </a>
        </h5>
      );
    } else if (days_left === 0) {
      return (
        <h5>
          <span className="badge badge-yellow">{t("expired")}</span>
          <a
            href="/#"
            className="link"
            onClick={(e) => this.updateJob(e, job_id)}
          >
            {t("extend")}
          </a>
        </h5>
      );
    } else if (days_left === -1) {
      return (
        <h5>
          <span className="badge badge-yellow">{t("expired_1_day_ago")}</span>
          <a
            href="/#"
            className="link"
            onClick={(e) => this.updateJob(e, job_id)}
          >
            {t("extend")}
          </a>
        </h5>
      );
    } else {
      return (
        <h5>
          <span className="badge badge-yellow">
            {t("expired___days_ago", { days_left: days_left * -1 })}
          </span>
          <a
            href="/#"
            className="link"
            onClick={(e) => this.updateJob(e, job_id)}
          >
            {t("extend")}
          </a>
        </h5>
      );
    }
  };

  cancelTheAction = (e) => {
    e.preventDefault();
    this.setState((prev_state) => ({
      ...prev_state,
      change_state_message_modal: {
        open: false,
        text: "",
        action_type: "",
        the_action: "",
        job_id: -1,
      },
    }));
  };

  postJob = (e, job_id) => {
    e.preventDefault();
    const { t } = this.props;
    // console.log("post!");
    this.setState((prev_state) => ({
      ...prev_state,
      change_state_message_modal: {
        open: true,
        text: t("are_you_sure_to_post_this_draft_"),
        action_type: "publish",
        the_action: this.continuePostJob,
        job_id: job_id,
      },
    }));
  };

  continuePostJob(e, job_id) {
    e.preventDefault();
    const { active_company_id } = this.state;
    this.setState({ loading: true });
    let options = {
      method: "PUT",
      url: config.api_url(
        `/employers/${active_company_id}/jobs/${job_id}/state/published`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          const { t } = this.props;
          this.fetchJobs(["all", "draft", "published"]);
          this.props.addFlashMessage(
            true,
            t("flash_messages:job_post_published_successfully_"),
            "",
            "with-auto-disappear",
            ""
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  closeJob = (e, job_id) => {
    e.preventDefault();
    const { t } = this.props;
    this.setState((prev_state) => ({
      ...prev_state,
      change_state_message_modal: {
        open: true,
        text: t("are_you_sure_to_close_this_job_"),
        action_type: "close",
        the_action: this.continueCloseJob,
        job_id: job_id,
      },
    }));
  };

  continueCloseJob = (e, job_id) => {
    e.preventDefault();
    const { active_company_id } = this.state;
    this.setState({ loading: true });
    let options = {
      method: "PUT",
      url: config.api_url(
        `/employers/${active_company_id}/jobs/${job_id}/state/closed`
      ),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          this.fetchJobs(["all", "published", "closed"]);
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  deleteJob = (e, job_id) => {
    e.preventDefault();
    const { t } = this.props;
    this.setState((prev_state) => ({
      ...prev_state,
      change_state_message_modal: {
        open: true,
        text: t("are_you_sure_to_delete_this_draft_"),
        action_type: "delete",
        the_action: this.continueDeleteJob,
        job_id: job_id,
      },
    }));
  };

  continueDeleteJob = (e, job_id) => {
    e.preventDefault();
    const { active_company_id } = this.state;
    this.setState({ loading: true });
    let options = {
      method: "DELETE",
      url: config.api_url(`/employers/${active_company_id}/jobs/${job_id}`),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        // TODO: 前提是只有 draft 才有 delete，下面更新的只有 delete 狀態的 jobs list
        this.fetchJobs(["all", "draft"]);
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  };

  editJob = (e, job_id) => {
    e.preventDefault();
    const internal_uri = new InternalUri();
    this.props.history.push(
      internal_uri.formatted_dashboard_job_page_path(job_id, "edit")
    );
  };

  reopenJob = (e, job_id) => {
    e.preventDefault();
    const internal_uri = new InternalUri();
    this.props.history.push(
      internal_uri.formatted_dashboard_job_page_path(job_id, "reopen")
    );
  };

  updateJob(e, job_id) {
    e.preventDefault();
    const internal_uri = new InternalUri();
    this.props.history.push(
      internal_uri.formatted_dashboard_job_page_path(job_id, "update")
    );
  }

  updateAllJobs(e) {
    e.preventDefault();
    const { active_company_id } = this.state;
    this.setState({ loading: true });
    let options = {
      method: "PATCH",
      url: config.api_url(`/employers/${active_company_id}/jobs/renew`),
      headers: headers.getItemsFromLocalStorage(),
      json: true,
    };
    axios(options)
      .then((response) => {
        headers.updateItemsToLocalStorage(response.headers);
        this.props.updateConnectionOffModal(false);

        if (this._isMounted) {
          const { t } = this.props;
          this.fetchJobs(["all", "published"]);
          this.props.addFlashMessage(
            true,
            t(
              "flash_messages:all_published_jobs_have_been_updated_and_will_last_for_60_days_"
            ),
            "",
            "with-auto-disappear",
            ""
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          headers.updateItemsToLocalStorage(error.response.headers);
          if (error.response.status === 401) window.location.reload();
        }

        if (this._isMounted) {
          if (typeof error.response === "undefined") {
            window.location.reload();
            this.props.updateConnectionOffModal(true);
          }

          this.setState((prev_state) => ({
            ...prev_state,
            loading: false,
            error_messages:
              error.response &&
              error.response.data &&
              error.response.data.status === "error"
                ? error.response.data.errors
                : {
                    full_message: "There was an error. Please try again later.",
                  },
          }));

          page_smothly_scroll_to(0, 300);
        }
      });
  }

  handleTabChange(tab_index) {
    this.setState({ active_tab_index: tab_index });
  }

  setCurrentPage = (key, page) => {
    page_smothly_scroll_to(0, 300);
    this.setState({ loading: true });
    this.parameters[key].paginator.current_page = page;
    this.fetchJobs([key]);
  };

  onSortedChange = (newSorted, key) => {
    if (newSorted && newSorted.length) {
      this.parameters[key].criteria.name = newSorted[0].id;
      this.parameters[key].criteria.sortBy = newSorted[0].desc ? "desc" : "asc";
      this.setCurrentPage(key, 1);
    }
  };

  render() {
    const {
      plans_selected_value,
      options,
      access_control_list,
      data,
      active_tab_index,
      change_state_message_modal,
      loading,
      published_states,
      error_messages,
    } = this.state;
    const { setCurrentPage, parameters } = this;
    const {
      handleUpdatable,
      cancelTheAction,
      updateAllJobs,
      handleTabChange,
      handleSelectChange,
    } = this;
    const { t } = this.props;
    const internal_uri = new InternalUri();

    if (!access_control_list.jobs_manageable) {
      return (
        <div className="dashboard-jobs">
          <div className="container-fluid">
            <h6 className="breadcrumb">
              <Link to={internal_uri.formatted_dashboard_index_page_path()}>
                {t("general:home")}
              </Link>
              {" / "}
              {t("general:job_list")}
            </h6>
            <div className="title-with-buttons">
              <h2>{t("general:job_list")}</h2>
              <Link
                to={internal_uri.formatted_dashboard_create_job_page_path()}
                className="btn btn-larger btn-float btn-fill"
              >
                <h5>{t("general:post_a_job")}</h5>
              </Link>
              <div className="select-wrapper" />
            </div>
            {t(
              "general:you_don_t_have_the_proper_privilege_level_to_visit_this_page_"
            )}
          </div>
        </div>
      );
    }

    const columns_pc = [
      {
        Header: () => <h5>{t("title")}</h5>,
        accessor: "job",
        id: "title",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return (
            <div>
              {job.has_event() ? (
                <span className="icon-event tooltip-wrapper right">
                  <IconSpeedInterview />
                  <div className="tooltip">
                    <h6>{t(job.events[0].get_i18n_key())}</h6>
                  </div>
                </span>
              ) : (
                ""
              )}
              <Link
                to={internal_uri.formatted_frontend_job_page_path(
                  job.id,
                  job.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="table-data job-title">
                  {job.formatted_title()}
                </h5>
              </Link>
              <h6 className="table-data location">
                {job.formatted_primary_location(t)}
              </h6>
            </div>
          );
        },
        width: 280,
      },
      {
        Header: () => <h5>{t("salary")}</h5>,
        accessor: "job",
        id: "minimum_salary",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          if (job.is_negotiable()) {
            return (
              <div className="tooltip-wrapper">
                <h6 className="table-data number">{t("general:negotiable")}</h6>
                <span className="tooltip">
                  <h6>{t("general:negotiable_description")}</h6>
                </span>
              </div>
            );
          }
          return (
            <div>
              <h6 className="table-data number">
                {job.formatted_salary_range()}
              </h6>
              <h6 className="table-data unit">
                ({job.formatted_salary_currency()}
                {job.formatted_salary_paid_period()})
              </h6>
            </div>
          );
        },
        width: 150,
      },
      {
        Header: () => <h5>{t("applications")}</h5>,
        accessor: "job",
        id: "job_applications_count",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return job.job_applications_count ? (
            <Link
              className="link"
              to={`${internal_uri.formatted_dashboard_applications_page_path()}?job=${
                job.id
              }`}
            >
              <h5>{job.job_applications_count}</h5>
            </Link>
          ) : (
            ""
          );
        },
        width: 140,
      },
      {
        Header: () => <h5>{t("active_plan")}</h5>,
        accessor: "job",
        id: "active_plan",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return (
            <div>
              <h6 className="table-data">
                {
                  {
                    post_only: t("general:plan_post"),
                    social_referral: t("general:plan_hire"),
                    custom_referral: t("general:plan_hire_"),
                  }[job.plan_name]
                }
              </h6>
            </div>
          );
        },
        width: 140,
      },
      {
        Header: () => <h5>{t("status")}</h5>,
        accessor: "job",
        id: "updated_at",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          if (job.published_state_key === "draft") {
            return (
              <div className="status-wrapper">
                <div>
                  <h6 className="table-data">
                    {t(`states:${job.published_state_key}`)}
                  </h6>
                </div>
                <div className="actions-wrapper">
                  <button
                    className="btn btn-smaller btn-flat btn-fill btn-post"
                    onClick={(e) => this.postJob(e, job.id)}
                  >
                    <h5>{t("general:post")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.editJob(e, job.id)}
                  >
                    <h5>{t("general:edit")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost btn-delete"
                    onClick={(e) => this.deleteJob(e, job.id)}
                  >
                    <h5>{t("general:delete")}</h5>
                  </button>
                </div>
              </div>
            );
          } else if (job.published_state_key === "published") {
            return (
              <div className="status-wrapper">
                {
                  <div>
                    <h6 className="table-data">
                      {t("general:updated_on")}{" "}
                      {moment(job.updated_at).format("YYYY/MM/DD")}
                    </h6>
                    {handleUpdatable(job.id, job.updated_at)}
                  </div>
                }
                <div className="actions-wrapper">
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.editJob(e, job.id)}
                  >
                    <h5>{t("general:edit")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost btn-close-job"
                    onClick={(e) => this.closeJob(e, job.id)}
                  >
                    <h5>{t("general:close")}</h5>
                  </button>
                  {/*<button className="btn btn-smaller btn-flat btn-ghost btn-more">*/}
                  {/*<img src={icon_more} alt="" />*/}
                  {/*<div className="btn btn-larger btn-float btn-hollow btn-duplicate">*/}
                  {/*<h5>Duplicate</h5>*/}
                  {/*</div>*/}
                  {/*</button>*/}
                </div>
              </div>
            );
          } else if (job.published_state_key === "closed") {
            return (
              <div className="status-wrapper">
                <div>
                  {job.closed_at ? (
                    <h6 className="table-data">
                      {t("general:closed_on")}{" "}
                      {moment(job.closed_at).format("YYYY/MM/DD")}
                    </h6>
                  ) : (
                    <h6 className="table-data">
                      {t(`states:${job.closed_at}`)}
                    </h6>
                  )}
                </div>
                <div className="actions-wrapper">
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.reopenJob(e, job.id)}
                  >
                    <h5>{t("general:reopen")}</h5>
                  </button>
                  {/*<button className="btn btn-smaller btn-flat btn-ghost btn-more">*/}
                  {/*<img src={icon_more} alt="" />*/}
                  {/*<div className="btn btn-larger btn-float btn-hollow btn-duplicate">*/}
                  {/*<h5>Duplicate</h5>*/}
                  {/*</div>*/}
                  {/*</button>*/}
                </div>
              </div>
            );
          }
        },
        width: 400,
      },
    ];

    // 992px
    const columns_tl = [
      {
        expander: true,
        show: false,
      },
      {
        Header: () => <h5>{t("title")}</h5>,
        accessor: "job",
        id: "title",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return (
            <div>
              <Link
                to={internal_uri.formatted_frontend_job_page_path(
                  job.id,
                  job.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="table-data job-title">
                  {job.formatted_title()}
                </h5>
              </Link>
              <h6 className="table-data location">
                {job.formatted_primary_location(t)}
              </h6>
            </div>
          );
        },
      },
      {
        Header: () => <h5>{t("salary")}</h5>,
        accessor: "job",
        id: "minimum_salary",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          if (job.is_negotiable()) {
            return (
              <div className="tooltip-wrapper">
                <h6 className="table-data number">{t("general:negotiable")}</h6>
                <span className="tooltip">
                  <h6>{t("general:negotiable_description")}</h6>
                </span>
              </div>
            );
          }
          return (
            <div>
              <h6 className="table-data number">
                {job.formatted_salary_range()}
              </h6>
              <h6 className="table-data unit">
                ({job.formatted_salary_currency()}
                {job.formatted_salary_paid_period()})
              </h6>
            </div>
          );
        },
        width: 115,
      },
      {
        Header: () => <h5>{t("applications")}</h5>,
        accessor: "job",
        id: "job_applications_count",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return job.job_applications_count ? (
            <Link
              className="link"
              to={`${internal_uri.formatted_dashboard_applications_page_path()}?job=${
                job.id
              }`}
            >
              <h5>{job.job_applications_count}</h5>
            </Link>
          ) : (
            ""
          );
        },
        width: 140,
      },
      {
        Header: () => <h5>{t("active_plan")}</h5>,
        accessor: "job",
        id: "active_plan",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return (
            <div>
              <h6 className="table-data">
                {
                  {
                    post_only: t("general:plan_post"),
                    social_referral: t("general:plan_hire"),
                    custom_referral: t("general:plan_hire_"),
                  }[job.plan_name]
                }
              </h6>
            </div>
          );
        },
        width: 140,
      },
      {
        Header: () => <h5>{t("status")}</h5>,
        accessor: "job",
        id: "updated_at",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          if (job.published_state_key === "draft") {
            return (
              <div className="status-wrapper">
                <div>
                  <h6 className="table-data">
                    {t(`states:${job.published_state_key}`)}
                  </h6>
                </div>
              </div>
            );
          } else if (job.published_state_key === "published") {
            return (
              <div className="status-wrapper">
                <div className="inner-wrapper">
                  <h6>
                    {t("general:updated_on")}{" "}
                    {moment(job.updated_at).format("YYYY/MM/DD")}
                  </h6>
                  {handleUpdatable(job.id, job.updated_at)}
                </div>
              </div>
            );
          } else if (job.published_state_key === "closed") {
            return (
              <div className="status-wrapper">
                <div>
                  {job.closed_at ? (
                    <h6 className="table-data">
                      {t("general:closed_on")}{" "}
                      {moment(job.closed_at).format("YYYY/MM/DD")}
                    </h6>
                  ) : (
                    <h6 className="table-data">
                      {t(`states:${job.closed_at}`)}
                    </h6>
                  )}
                </div>
              </div>
            );
          }
        },
        width: 175,
      },
    ];

    // 600px
    const columns_mb = [
      {
        Header: () => <h5>{t("title")}</h5>,
        accessor: "job",
        id: "title",
        Cell: (props) => {
          const job = new Job(JSON.parse(props.value));
          return (
            <div>
              <Link
                to={internal_uri.formatted_frontend_job_page_path(
                  job.id,
                  job.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h5 className="table-data job-title">
                  {job.formatted_title()}
                </h5>
              </Link>
              <h6 className="table-data location">
                {job.formatted_primary_location(t)}
              </h6>
              <table>
                <tbody>
                  <tr>
                    <th>
                      <h5>
                        <h6>{t("salary")}</h6>
                      </h5>
                    </th>
                    {job.is_negotiable() ? (
                      <td>
                        <h6 className="table-data number">
                          {t("general:negotiable")}
                          <br />
                          {t("general:negotiable_description")}
                        </h6>
                      </td>
                    ) : (
                      <td>
                        <h6 className="table-data number">
                          {job.formatted_salary_range()}
                        </h6>
                        <h6 className="table-data unit">
                          ({job.formatted_salary_currency()}
                          {job.formatted_salary_paid_period()})
                        </h6>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <th>
                      <h5>{t("applications")}</h5>
                    </th>
                    <td>
                      {job.job_applications_count ? (
                        <Link
                          className="link"
                          to={`${internal_uri.formatted_dashboard_applications_page_path()}?job=${
                            job.id
                          }`}
                        >
                          <h5>{job.job_applications_count}</h5>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>
                        {
                          {
                            post_only: t("general:plan_post"),
                            social_referral: t("general:plan_hire"),
                            custom_referral: t("general:plan_hire_"),
                          }[job.plan_name]
                        }
                      </h5>
                    </th>
                    <td>
                      <div className="status-wrapper">
                        <div>
                          <h6>{job.plan_name}</h6>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <h5>{t("status")}</h5>
                    </th>
                    <td>
                      {job.published_state_key === "draft" ? (
                        <div className="status-wrapper">
                          <div>
                            <h6>{t(`states:${job.published_state_key}`)}</h6>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {job.published_state_key === "published" ? (
                        <div className="status-wrapper">
                          <div className="inner-wrapper">
                            <h6>
                              {t("general:updated_on")}{" "}
                              {moment(job.updated_at).format("YYYY/MM/DD")}
                            </h6>
                            {handleUpdatable(job.id, job.updated_at)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {job.published_state_key === "closed" ? (
                        <div className="status-wrapper">
                          {job.closed_at ? (
                            <h6>
                              {t("general:closed_on")}{" "}
                              {moment(job.closed_at).format("YYYY/MM/DD")}
                            </h6>
                          ) : (
                            <h6>{t(`states:${job.closed_at}`)}</h6>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {job.published_state_key === "draft" ? (
                <div>
                  <button
                    className="btn btn-smaller btn-flat btn-fill btn-post"
                    onClick={(e) => this.postJob(e, job.id)}
                  >
                    <h5>{t("general:post")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.editJob(e, job.id)}
                  >
                    <h5>{t("general:edit")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost btn-delete"
                    onClick={(e) => this.deleteJob(e, job.id)}
                  >
                    <h5>{t("general:delete")}</h5>
                  </button>
                </div>
              ) : (
                ""
              )}
              {job.published_state_key === "published" ? (
                <div>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.editJob(e, job.id)}
                  >
                    <h5>{t("general:edit")}</h5>
                  </button>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost btn-close-job"
                    onClick={(e) => this.closeJob(e, job.id)}
                  >
                    <h5>{t("general:close")}</h5>
                  </button>
                </div>
              ) : (
                ""
              )}
              {job.published_state_key === "closed" ? (
                <div>
                  <button
                    className="btn btn-smaller btn-flat btn-ghost"
                    onClick={(e) => this.reopenJob(e, job.id)}
                  >
                    <h5>{t("general:reopen")}</h5>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ];

    return (
      <div className="dashboard-jobs test-note">
        <div className="container-fluid">
          <h6 className="breadcrumb">
            <Link to={internal_uri.formatted_dashboard_index_page_path()}>
              {t("general:home")}
            </Link>{" "}
            / {t("general:job_list")}
          </h6>
          <div className="title-with-buttons">
            <h2>{t("general:job_list")}</h2>
            <Link
              to={internal_uri.formatted_dashboard_create_job_page_path()}
              className="btn btn-larger btn-float btn-fill"
            >
              <h5>{t("general:post_a_job")}</h5>
            </Link>
            {Object.keys(data).length === 0 ||
            data.all.length ||
            data.draft.length ||
            data.published.length ||
            data.closed.length ? (
              <button
                className="btn btn-larger btn-float btn-hollow btn-extend"
                onClick={(e) => updateAllJobs(e)}
              >
                <h5>{t("extend_all_posts")}</h5>
              </button>
            ) : (
              ""
            )}
          </div>
          {error_messages.full_message ? (
            <h6 className="message error-message">
              {error_messages.full_message}
            </h6>
          ) : (
            ""
          )}

          <label htmlFor="">
            <h5>{t("general:choose_plan")}</h5>
          </label>
          <div className="form-row form-row-dire-col">
            <div className="form-col">
              <div className="select-wrapper">
                <Select
                  className="drop-down drop-down-larger drop-down-grey"
                  clearable={false}
                  value={plans_selected_value}
                  onChange={(value) =>
                    handleSelectChange("plans_selected_value", value)
                  }
                  options={[
                    { label: t("general:plan_post"), value: "post_only" },
                    { label: t("general:plan_hire"), value: "social_referral" },
                    {
                      label: t("general:plan_hire_"),
                      value: "custom_referral",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="form-col">
              {error_messages.industry && error_messages.industry.length ? (
                <h6 className="message error-message">
                  {error_messages.industry.join(", ")}
                </h6>
              ) : (
                ""
              )}
            </div>
          </div>

          {Object.keys(data).length === 0 ||
          data.all.length ||
          data.draft.length ||
          data.published.length ||
          data.closed.length ? (
            <Tabs
              className="react-tabs tabs"
              selectedIndex={active_tab_index}
              onSelect={(index) => handleTabChange(index)}
            >
              <TabList>
                {Object.keys(published_states).map((key, i) => (
                  <Tab key={i}>
                    <h5>
                      {t(`states:${key}`)}
                      {parameters[key].paginator.total_entries ? (
                        <span className="counting-block">
                          {parameters[key].paginator.total_entries}
                        </span>
                      ) : (
                        ""
                      )}
                    </h5>
                  </Tab>
                ))}
              </TabList>
              <div className="tabs-mb">
                <div className="select-wrapper">
                  <Select
                    className="drop-down drop-down-larger drop-down-grey"
                    clearable={false}
                    searchable={false}
                    value={active_tab_index}
                    onChange={(value) => handleTabChange(value.value)}
                    options={Object.keys(published_states).map((key, i) => ({
                      value: i,
                      label: t(`states:${key}`),
                      count: parameters[key].paginator.total_entries,
                    }))}
                    valueRenderer={(option) => (
                      <div>
                        {option.label}
                        {option.count ? (
                          <span className="counting-block">{option.count}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    optionRenderer={(option) => (
                      <div>
                        {option.label}
                        {option.count ? (
                          <span className="counting-block">{option.count}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  />
                </div>
              </div>

              {Object.keys(published_states).map((key, i) => (
                <TabPanel key={i}>
                  <div className="table-wrapper-pc table-wrapper block">
                    <ReactTable
                      loading={loading}
                      data={data[key]}
                      columns={columns_pc}
                      defaultPageSize={parameters[key].paginator.per_age}
                      minRows={0}
                      showPagination={false}
                      sorted={[
                        {
                          id: parameters[key].criteria.name,
                          desc: parameters[key].criteria.sortBy === "desc",
                        },
                      ]}
                      defaultSortDesc={true}
                      defaultSortMethod={(a, b, desc) => (desc ? -1 : 1)}
                      onSortedChange={(newSorted, column, shiftKey) =>
                        this.onSortedChange(newSorted, key)
                      }
                      noDataText={t("general:your_list_is_empty_")}
                    />
                  </div>
                  <div className="table-wrapper-tl table-wrapper block">
                    <div className="table-wrapper block">
                      <ReactTable
                        loading={loading}
                        data={data[key]}
                        columns={columns_tl}
                        defaultPageSize={parameters[key].paginator.per_age}
                        minRows={0}
                        showPagination={false}
                        defaultSortDesc={true}
                        defaultSortMethod={(a, b, desc) => (desc ? -1 : 1)}
                        onSortedChange={(newSorted, column, shiftKey) =>
                          this.onSortedChange(newSorted, key)
                        }
                        noDataText={t("general:your_list_is_empty_")}
                        expanded={data[key] ? data[key].map((i) => i) : false}
                        SubComponent={(row) => {
                          const job = new Job(JSON.parse(row.original.job));
                          if (job.published_state_key === "draft") {
                            return (
                              <div className="extended-sub-row-wrapper">
                                <div className="rt-tr">
                                  <div className="rt-td">
                                    <button
                                      className="btn btn-smaller btn-flat btn-fill btn-post"
                                      onClick={(e) => this.postJob(e, job.id)}
                                    >
                                      <h5>{t("general:post")}</h5>
                                    </button>
                                    <button
                                      className="btn btn-smaller btn-flat btn-ghost"
                                      onClick={(e) => this.editJob(e, job.id)}
                                    >
                                      <h5>{t("general:edit")}</h5>
                                    </button>
                                    <button
                                      className="btn btn-smaller btn-flat btn-ghost btn-delete"
                                      onClick={(e) => this.deleteJob(e, job.id)}
                                    >
                                      <h5>{t("general:delete")}</h5>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (job.published_state_key === "published") {
                            return (
                              <div className="extended-sub-row-wrapper">
                                <div className="rt-tr">
                                  <div className="rt-td">
                                    <button
                                      className="btn btn-smaller btn-flat btn-ghost"
                                      onClick={(e) => this.editJob(e, job.id)}
                                    >
                                      <h5>{t("general:edit")}</h5>
                                    </button>
                                    <button
                                      className="btn btn-smaller btn-flat btn-ghost btn-close-job"
                                      onClick={(e) => this.closeJob(e, job.id)}
                                    >
                                      <h5>{t("general:close")}</h5>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (job.published_state_key === "closed") {
                            return (
                              <div className="extended-sub-row-wrapper">
                                <div className="rt-tr">
                                  <div className="rt-td">
                                    <button
                                      className="btn btn-smaller btn-flat btn-ghost"
                                      onClick={(e) => this.reopenJob(e, job.id)}
                                    >
                                      <h5>{t("general:reopen")}</h5>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="table-wrapper-mb table-wrapper block">
                    <div className="table-wrapper block">
                      <ReactTable
                        loading={loading}
                        data={data[key]}
                        columns={columns_mb}
                        defaultPageSize={parameters[key].paginator.per_age}
                        minRows={0}
                        showPagination={false}
                        defaultSortDesc={true}
                        defaultSortMethod={(a, b, desc) => (desc ? -1 : 1)}
                        onSortedChange={(newSorted, column, shiftKey) =>
                          this.onSortedChange(newSorted, key)
                        }
                        noDataText={t("general:your_list_is_empty_")}
                      />
                    </div>
                  </div>
                  {parameters[key].paginator.total_entries ? (
                    <div>
                      <div className="pagination-wrapper-pc hidden-xs hidden-sm">
                        <Pagination
                          currentPage={parameters[key].paginator.current_page}
                          pagesCount={parameters[key].paginator.total_pages}
                          pagedCount={5}
                          previous_page={
                            parameters[key].paginator.previous_page
                          }
                          next_page={parameters[key].paginator.next_page}
                          setCurrentPage={(page) => setCurrentPage(key, page)}
                        />
                      </div>
                      <div className="pagination-wrapper-mb">
                        <Pagination
                          currentPage={parameters[key].paginator.current_page}
                          pagesCount={parameters[key].paginator.total_pages}
                          pagedCount={4}
                          previous_page={
                            parameters[key].paginator.previous_page
                          }
                          next_page={parameters[key].paginator.next_page}
                          setCurrentPage={(page) => setCurrentPage(key, page)}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </TabPanel>
              ))}
            </Tabs>
          ) : (
            <div className="blank-job-wrapper">
              <div className="graph">
                <div
                  className="image-wrapper"
                  style={{ backgroundImage: `url(${blank_job})` }}
                />
              </div>
              <h2>{t("no_jobs_posted")}</h2>
              <p>{t("you_haven_t_posted_any_jobs_yet_")}</p>
              <Link
                to={internal_uri.formatted_dashboard_create_job_page_path()}
                className="btn btn-larger btn-flat btn-fill"
              >
                <h5>{t("general:post_a_job")}</h5>
              </Link>
            </div>
          )}

          <CSSTransitionGroup
            component="div"
            transitionName="modal-wrapper-with-modal-slide"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {change_state_message_modal.open ? (
              <div className="modal-wrapper double-confirm-modal-wrapper">
                <div className="modal-inner-wrapper">
                  <div className="modal-bg" />
                  <div className="modal double-confirm-modal with-pop-up-icon">
                    {change_state_message_modal.action_type === "delete" ? (
                      <div
                        className="image-wrapper"
                        style={{
                          backgroundImage: `url(${modal_pop_up_delete}`,
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {change_state_message_modal.action_type === "close" ? (
                      <div
                        className="image-wrapper"
                        style={{ backgroundImage: `url(${modal_pop_up_close}` }}
                      />
                    ) : (
                      ""
                    )}
                    <div className="content">
                      <h4 className="modal-title">
                        {change_state_message_modal.text}
                      </h4>
                      {error_messages.full_message ? (
                        <h6 className="message error-message">
                          {error_messages.full_message}
                        </h6>
                      ) : (
                        ""
                      )}
                      <div className="buttons-wrapper">
                        <button
                          className="btn btn-larger btn-flat btn-ghost"
                          onClick={(e) => cancelTheAction(e)}
                        >
                          <h5>{t("general:cancel")}</h5>
                        </button>
                        {change_state_message_modal.action_type === "delete" ? (
                          <button
                            className="btn btn-larger btn-flat btn-fill btn-danger"
                            onClick={(e) =>
                              change_state_message_modal.the_action(
                                e,
                                change_state_message_modal.job_id
                              )
                            }
                          >
                            <h5>{t("general:delete")}</h5>
                          </button>
                        ) : (
                          ""
                        )}
                        {change_state_message_modal.action_type === "close" ? (
                          <button
                            className="btn btn-larger btn-flat btn-fill btn-danger"
                            onClick={(e) =>
                              change_state_message_modal.the_action(
                                e,
                                change_state_message_modal.job_id
                              )
                            }
                          >
                            <h5>{t("general:close")}</h5>
                          </button>
                        ) : (
                          ""
                        )}
                        {change_state_message_modal.action_type ===
                        "publish" ? (
                          <button
                            className="btn btn-larger btn-flat btn-fill btn-success"
                            onClick={(e) =>
                              change_state_message_modal.the_action(
                                e,
                                change_state_message_modal.job_id
                              )
                            }
                          >
                            <h5>{t("general:post")}</h5>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

export default withNamespaces(["dashboard_jobs_page", "general", "states"])(
  JobsPage
);
